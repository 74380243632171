import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// Thunks for API calls
export const getPracticeArea = createAsyncThunk(
  "case/getPracticeArea",
  async ({ url, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Token ${token}` },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getCases = createAsyncThunk(
  "case/getCases",
  async ({ url, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Token ${token}` },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
export const getEditCaseData = createAsyncThunk(
  "getEditCaseData",
  async ({ url, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Token ${token}` },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const update = createAsyncThunk(
  "update",
  async ({ url, body, token }, { rejectWithValue }) => {
    try {
      const response = await fetch(url, {
        method: "PATCH", // or "PUT"
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(body),
      });
      if (!response.ok) {
        throw new Error("Failed to update case");
      }
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getCaseTemplate = createAsyncThunk(
  "case/getCaseTemplate",
  async ({ url, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Token ${token}` },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getEdtiTempData = createAsyncThunk(
  "case/getEdtiTempData",
  async ({ url, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Token ${token}` },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const createCase = createAsyncThunk(
  "case/createCase",
  async ({ url, body, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(url, body, {
        headers: { Authorization: `Token ${token}` },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const createCaseTemplate = createAsyncThunk(
  "case/createCaseTemplate",
  async ({ url, body, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(url, body, {
        headers: { Authorization: `Token ${token}` },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Slice
const CaseSlice = createSlice({
  name: "case",
  initialState: {
    allCase: null,
    allCaseTemplate: null,
    allPracticeArea: null,
    editCaseData: null,
    editcaseTemplateData: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // getPracticeArea
      .addCase(getPracticeArea.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPracticeArea.fulfilled, (state, action) => {
        state.loading = false;
        state.allPracticeArea = action.payload;
      })
      .addCase(getPracticeArea.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // getCases
      .addCase(getCases.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCases.fulfilled, (state, action) => {
        state.loading = false;
        state.allCase = action.payload;
      })
      .addCase(getCases.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // getCaseTemplate
      .addCase(getCaseTemplate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCaseTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.allCaseTemplate = action.payload;
      })
      .addCase(getCaseTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getEdtiTempData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEdtiTempData.fulfilled, (state, action) => {
        state.loading = false;
        state.editcaseTemplateData = action?.payload;
      })
      .addCase(getEdtiTempData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // createCase
      .addCase(createCase.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCase.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createCase.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // createCaseTemplate
      .addCase(createCaseTemplate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCaseTemplate.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createCaseTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //edit case data
      .addCase(getEditCaseData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEditCaseData.fulfilled, (state, action) => {
        state.loading = false;
        state.editCaseData = action.payload;
      })
      .addCase(getEditCaseData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //edit case and template from here
      .addCase(update.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(update.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(update.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const CaseReducer = CaseSlice.reducer;
