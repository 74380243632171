import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { pageScrollStyle } from "../styles/styles";

export default function DataTable({
  rows,
  columns,
  CustomToolbar,
  CustomNoRowsOverlay,
  loading,
}) {
  return (
    <Box
      sx={{
        height: 400,
        width: "100%",
        "& .MuiDataGrid-root": {
          overflowX: "auto",
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        loading={loading}
        slotProps={{
          loadingOverlay: {
            variant: "skeleton",
            noRowsVariant: "skeleton",
            rowCount: 5,
          },
        }}
        initialState={{
          pinnedColumns: {
            left: ["desk"],
          },
        }}
        slots={{
          toolbar: CustomToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        components={{
          Toolbar: GridToolbar,
        }}
        disableRowSelectionOnClick
        disableSelectionOnClick
        sx={{
          ...pageScrollStyle,
          border: "1px solid #e5e5e5",
          ".MuiDataGrid-cell": {
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            borderBottom: "1px solid #e5e5e5",
            textTransform: "capitalize",
          },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "#00b3b02c",
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-main": {
            overflow: "auto",
            scrollbarWidth: "thin",
            scrollBehavior: "smooth",
          },
          "& .MuiDataGrid-virtualScroller": {
            overflow: "auto",
            scrollBehavior: "smooth",
          },
        }}
      />
    </Box>
  );
}
