import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles, {
  debounce,
  formattedDate,
  pageScrollStyle,
} from "../styles/styles";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { useForm, Controller } from "react-hook-form";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EmptyBox from "../assets/empty-box.webp";
import { useDispatch, useSelector } from "react-redux";
import {
  createCustomField,
  getAllCustomField,
  updateCustomField,
} from "../redux/Apps/Lawyerapp/CustomFieldSlice";
import { CloseRounded } from "@mui/icons-material";
import { backend_api } from "../static/server";
import DataTable from "./DataTable";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingButton } from "@mui/lab";
import { DateCalendar } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const CustomNoRowsOverlay = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      p: "2rem",
      gap: "1rem",
    }}
  >
    <Box sx={{ height: "10rem" }}>
      <img
        src={EmptyBox}
        alt="Company Logo"
        style={{
          height: "100%",
          width: "100%",
          objectFit: "contain",
        }}
        onError={(e) => {
          e.onerror = null;
          e.target.src = require("../assets/no-preview.webp");
        }}
      />
    </Box>
    <Typography variant="body1">No Custom field available.</Typography>
  </Box>
);

const CustomToolbar = () => {
  return (
    <GridToolbarContainer className="flex justify-end px-2 py-2">
      <div className="flex gap-2">
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </div>
    </GridToolbarContainer>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const inputTypes = [
  "Matter select",
  "contact select",
  "text",
  "email",
  "number",
  "date",
  "select",
  "textarea",
];

const filterBtnsArr = [
  { option: "All", value: "all" },
  { option: "Task", value: "task" },
  { option: "Matter", value: "case" },
  { option: "Contacts", value: "contact" },
  { option: "Billing", value: "bill" },
];

const CustomeFields = () => {
  const dispatch = useDispatch();
  const [optionCount, setOptionCount] = useState(2);
  const [optionLenError, setOptionLenError] = useState(false);
  const [showDash, setShowDash] = useState(0);
  const [addFieldOpenDilog, setAddFieldOpenDilog] = useState(false);
  const [searchFieldQuery, setSearchFieldQuery] = useState(null);
  const [loader, setLoader] = useState(false);
  const [deleteFieldDilog, setDeleteFieldDilog] = useState(false);
  const [deleteFieldId, setDeleteFieldId] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editFieldData, setEditFieldData] = useState(null);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterDateValue, setFilterDateValue] = useState(null);
  const [hasActiveFilters, setHasActiveFilters] = useState(false);
  const { token } = useSelector((data) => data.user);
  const { userId, lawerId } = useSelector((state) => ({
    userId: state?.user?.userId,
    lawerId: state?.user?.lawerId,
  }));
  const { allCustomField, loadingCustomField, error } = useSelector(
    (state) => state?.customField
  );

  useEffect(() => {
    fetchCustomFields();
  }, [dispatch, token]);

  const fetchCustomFields = (filterParams = "") => {
    dispatch(
      getAllCustomField({
        url: `${backend_api}custom-field/${filterParams}`,
        token: token,
      })
    );
  };

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      created_by: userId,
      under_lawer: lawerId,
      field_for: "",
      field_name: "",
      field_data: {
        type: "",
        placeholder: "",
        validation: false,
        required: false,
        default_apply: false,
        options: [],
      },
    },
  });
  const field_type = watch("field_data.type");

  const handleFilterBtn = (key) => {
    setShowDash(filterBtnsArr.findIndex((btn) => btn.value === key));
    if (key === "all") {
      fetchCustomFields();
    } else {
      fetchCustomFields(`?field_for=${key}`);
    }
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();

    if (data.field_data.type === "select") {
      const validOptions = data.field_data.options.filter(
        (option) => option && option.trim() !== ""
      );

      if (validOptions.length < 2) {
        toast.error("Select type fields must have at least 2 options");
        return;
      }

      data.field_data.options = validOptions;
    }

    try {
      setLoader(true);

      if (isEditMode && editFieldData) {
        await dispatch(
          updateCustomField({
            url: `${backend_api}custom-field/${editFieldData.id}/`,
            body: data,
            token: token,
          })
        ).unwrap();
        toast.success("Custom Field updated successfully");
      } else {
        await dispatch(
          createCustomField({
            url: `${backend_api}custom-field/`,
            body: data,
            token: token,
          })
        ).unwrap();
        toast.success("Custom Field created successfully");
      }

      // Refresh the list
      await dispatch(
        getAllCustomField({
          url: `${backend_api}custom-field/`,
          token: token,
        })
      ).unwrap();

      reset();
      handleClose();
    } catch (error) {
      console.error(error);
      toast.error(
        error?.message || "Failed to save the field. Please try again."
      );
    } finally {
      setLoader(false);
    }
  };

  const handleClickOpen = () => {
    reset();
    setIsEditMode(false);
    setAddFieldOpenDilog(true);
  };

  const handleClose = () => {
    setAddFieldOpenDilog(false);
  };

  const getBtnActiveStyle = (key) => ({
    textTransform: "capitalize",
    transition: "0.3s",
    borderBottom: showDash === key ? "2px solid #00B3B0" : "none",
    borderRadius: "0rem",
  });

  const handleDeleteOption = (i) => {
    if (optionCount === 2) {
      setOptionLenError(true);
    } else {
      setOptionCount(optionCount - 1);
    }
    console.log("deleted");
  };

  const handleDeleteCustomField = async (id) => {
    setLoader(true);
    console.log(id);
    try {
      const res = await axios.delete(`${backend_api}custom-field/${id}/`, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });
      dispatch(
        getAllCustomField({
          url: `${backend_api}custom-field/`,
          token: token,
        })
      );
      toast.success("Field deleted successfully");
      return res;
    } catch (error) {
      toast.error("Failed to delete the Field. Please try again");
    } finally {
      setShowDash(0);
      setLoader(false);
      handleCloseDelteFieldDilog();
    }
  };

  const handleEditField = (item) => {
    console.log(item?.id);
    setIsEditMode(true);
    setEditFieldData(item);
    setAddFieldOpenDilog(true);
    const formValue =
      filterBtnsArr.find(
        (btn) =>
          btn.value === item.field_for ||
          btn.option.toLowerCase() === item.field_for?.toLowerCase()
      )?.value || item.field_for;

    setValue("field_for", formValue);
    setValue("field_name", item?.field_name);
    setValue("field_data.type", item?.field_data?.type);
    setValue("field_data.required", item?.field_data?.required);
    setValue("field_data.validation", item?.field_data?.validation);
    setValue("field_data.default_apply", item?.field_data?.default_apply);

    if (item?.field_data?.type === "select" && item?.field_data?.options) {
      const optionsLength = item.field_data.options.length;
      setOptionCount(optionsLength > 2 ? optionsLength : 2);

      item.field_data.options.forEach((option, index) => {
        setValue(`field_data.options.${index}`, option);
      });
    } else {
      setOptionCount(2);
    }
  };

  const debouncedFetchCases = useCallback(
    debounce((query) => {
      const url = query
        ? `${backend_api}custom-field/?field_name=${encodeURIComponent(query)}`
        : `${backend_api}custom-field/`;

      dispatch(
        getAllCustomField({
          url,
          token,
        })
      );
    }, 300),
    [dispatch, backend_api, token]
  );

  const handleFieldSearch = (e) => {
    const value = e.target.value;
    setSearchFieldQuery(value); // Update state
    debouncedFetchCases(value); // Trigger debounced function
  };

  const toggleDrawer = (newOpen) => () => {
    setOpenFilterDrawer(newOpen);
  };

  const handleDateChange = (date) => {
    console.log(date);
    setFilterDateValue(date);
    setHasActiveFilters(!!date);
  };

  const handleApplyFilter = async () => {
    setLoader(true);
    let filterParams = "";

    if (filterDateValue) {
      const formattedDate = dayjs(filterDateValue).format("YYYY-MM-DD");
      filterParams = `?created_at=${formattedDate}`;
    }

    try {
      await fetchCustomFields(filterParams);
      setIsFilterApplied(true);
      setOpenFilterDrawer(false);
    } catch (error) {
      toast.error("Failed to apply filters");
      console.error("Filter Error:", error);
    } finally {
      setLoader(false);
    }
  };

  const handleResetFiltersCustomField = () => {
    setFilterDateValue(null);
    setIsFilterApplied(false);
    setHasActiveFilters(false);
    fetchCustomFields();
  };

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      headerAlign: "left",
      renderCell: (item) => (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "0.2rem ",
              width: "100%",
            }}
          >
            <Tooltip title="Edit">
              <IconButton
                color="primary"
                size="small"
                onClick={() =>
                  handleEditField({
                    id: item?.row?.id,
                    field_for: item?.row?.field_for,
                    field_name: item?.row?.field_name,
                    field_data: {
                      type: item?.row?.field_type,
                      required: item?.row?.require === "true",
                      validation: item?.row?.validation === "true",
                      default_apply: false,
                      options:
                        item?.row?.field_type === "select"
                          ? item?.row?.options
                          : [],
                    },
                  })
                }
              >
                <DriveFileRenameOutlineRoundedIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                color="primary"
                size="small"
                onClick={() => handleOpenDelteFieldDilog(item.id)}
              >
                <DeleteRoundedIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
          </Box>
        </>
      ),
    },
    {
      field: "field_name",
      headerName: "Field Name",
      width: 250,
    },
    { field: "field_for", headerName: "Form Name", width: 250 },
    {
      field: "field_type",
      headerName: "Field Type",
      width: 150,
    },

    {
      field: "require",
      headerName: "Required",
      width: 150,
    },
    {
      field: "validation",
      headerName: "Validation",
      width: 150,
    },
    {
      field: "created_at",
      headerName: "Create At",
      width: 200,
    },
  ];

  const rows = useMemo(() => {
    if (!Array.isArray(allCustomField)) {
      console.error("allCustomField is not an array:", allCustomField);
      return [];
    }

    return allCustomField?.map((item) => ({
      id: item?.id ?? "-",
      field_name: item?.field_name ?? "-",
      field_for: item?.field_for
        ? filterBtnsArr?.find((value) => item?.field_for === value?.value)
            ?.option
        : "-",
      field_type: item?.field_data?.type ?? "-",
      require: item?.field_data?.required === true ? "true" : "false",
      validation: item?.field_data?.validation === true ? "true" : "false",
      created_at: formattedDate(item?.created_at) ?? "-",
    }));
  }, [allCustomField]);

  const handleOpenDelteFieldDilog = (id) => {
    setDeleteFieldId(id);
    setDeleteFieldDilog(true);
  };
  const handleCloseDelteFieldDilog = () => {
    setDeleteFieldDilog(false);
  };
  return (
    <>
      <div className="w-full">
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <div className="flex justify-between bg-white rounded-lg items-center shadow-md p-3">
            <h1 className={`${styles.title}`}>
              {`${filterBtnsArr[showDash]?.option}`} Custom Fields
            </h1>
            <Box className="flex gap-4">
              <Button variant="outlined" sx={{ textTransform: "capitalize" }}>
                Modify Order
              </Button>
              <Button
                variant="contained"
                sx={{ textTransform: "capitalize" }}
                onClick={() => handleClickOpen()}
              >
                Add field
              </Button>
            </Box>
          </div>

          <Box
            className="bg-white rounded-lg justify-between h-[80vh] p-2 w-full"
            sx={{ ...pageScrollStyle, overflow: "auto" }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "0.8rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    p: "0.2rem",
                    borderRadius: "0.5rem",
                  }}
                >
                  {filterBtnsArr?.map((item, i) => (
                    <Button
                      key={i}
                      size="small"
                      variant="text"
                      sx={getBtnActiveStyle(i)}
                      onClick={() => handleFilterBtn(item?.value)}
                    >
                      {item?.option}
                    </Button>
                  ))}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.8rem",
                  }}
                >
                  <Box>
                    <TextField
                      size="small"
                      placeholder="By name"
                      label="Search"
                      value={searchFieldQuery}
                      onChange={handleFieldSearch}
                      sx={{ width: "15rem", marginRight: "1rem" }}
                    />
                  </Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<FilterListRoundedIcon />}
                    onClick={toggleDrawer(true)}
                  >
                    Filter
                  </Button>
                </Box>
              </Box>

              <Box>
                <DataTable
                  rows={rows}
                  loading={loadingCustomField}
                  columns={columns}
                  CustomToolbar={CustomToolbar}
                  CustomNoRowsOverlay={CustomNoRowsOverlay}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </div>

      {/* dialog for adding custom field */}
      <Dialog
        open={addFieldOpenDilog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            p: "1rem",
          }}
        >
          <Box>
            <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
              {isEditMode ? "Edit Custom Field" : " Add Custom Field"}
            </Typography>
          </Box>
          <Box sx={{ width: "30rem" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{ display: "flex", gap: "1rem", flexDirection: "column" }}
              >
                <Box>
                  <Controller
                    name="field_for"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormControl
                        size="small"
                        fullWidth
                        error={errors.field_for}
                      >
                        <InputLabel
                          id="field_for-label"
                          sx={{ bgcolor: "#fff", px: "0.3rem" }}
                        >
                          Select Form
                        </InputLabel>
                        <Select
                          {...field}
                          size="small"
                          labelId="field_for-label"
                          fullWidth
                        >
                          {filterBtnsArr?.map((item, i) =>
                            item?.option === "All" &&
                            item?.value === "all" ? null : (
                              <MenuItem key={i} value={item?.value}>
                                {item?.option}
                              </MenuItem>
                            )
                          )}
                        </Select>

                        {errors.field_for && (
                          <Typography color="error" sx={{ fontSize: "0.8rem" }}>
                            Select form for use custom fields.
                          </Typography>
                        )}
                      </FormControl>
                    )}
                  />
                </Box>
                <Box>
                  <Controller
                    name="field_name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        size="small"
                        label="Field Name"
                        error={errors.field_data?.type}
                      />
                    )}
                  />
                  {errors.field_name && (
                    <Typography color="error" sx={{ fontSize: "0.8rem" }}>
                      This field is required
                    </Typography>
                  )}
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {optionLenError ? (
                      <Typography color="error" sx={{ fontSize: "0.8rem" }}>
                        This type contain minimum 2 options.
                      </Typography>
                    ) : null}
                  </Box>
                  <Controller
                    name="field_data.type"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormControl
                        size="small"
                        fullWidth
                        error={errors.field_data?.type}
                      >
                        <InputLabel
                          id="field_data-label"
                          sx={{ bgcolor: "#fff", px: "0.3rem" }}
                        >
                          Type
                        </InputLabel>
                        <Select {...field} size="small" fullWidth>
                          {inputTypes.map((item, i) => (
                            <MenuItem key={i} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  {errors.field_data?.type && (
                    <Typography color="error" sx={{ fontSize: "0.8rem" }}>
                      This field is required
                    </Typography>
                  )}
                  {field_type === "select" ? (
                    <Box
                      sx={{
                        mt: "0.5rem",
                        display: "flex",
                        justifyContent: "center ",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                          width: "100%",
                          maxHeight: "6rem",
                          overflowX: "auto",
                          ...pageScrollStyle,
                          pt: "0.3rem",
                        }}
                      >
                        {[...Array(optionCount)].map((item, i) => (
                          <Controller
                            key={i}
                            name={`field_data.options.${i}`}
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                error={errors.field_data?.options}
                                fullWidth
                                size="small"
                                label={`option${i + 1}`}
                                value={field.value || ""}
                                slotProps={{
                                  input: {
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Tooltip title="delete">
                                          <IconButton
                                            onClick={() =>
                                              handleDeleteOption(i)
                                            }
                                          >
                                            <DeleteRoundedIcon
                                              sx={{ fontSize: "1.3rem " }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </InputAdornment>
                                    ),
                                  },
                                }}
                              />
                            )}
                          />
                        ))}
                        {errors.field_data?.options && (
                          <Typography color="error" sx={{ fontSize: "0.8rem" }}>
                            These field is required
                          </Typography>
                        )}
                      </Box>
                      <Tooltip title="Add options">
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setOptionCount(optionCount + 1);
                            setOptionLenError(false);
                          }}
                        >
                          <AddRoundedIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ) : null}
                </Box>
                <Box>
                  <Controller
                    name="field_data.required"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <input
                            {...field}
                            type="checkbox"
                            checked={field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                            className="ml-3 mr-2"
                          />
                        }
                        label={
                          <Typography
                            sx={{ fontSize: "0.9rem", fontWeight: 400 }}
                          >
                            Required
                          </Typography>
                        }
                      />
                    )}
                  />
                  <Controller
                    name="field_data.validation"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <input
                            {...field}
                            type="checkbox"
                            checked={field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                            className="ml-3 mr-2"
                          />
                        }
                        label={
                          <Typography
                            sx={{ fontSize: "0.9rem", fontWeight: 400 }}
                          >
                            Validation
                          </Typography>
                        }
                      />
                    )}
                  />
                </Box>
                <Divider orientation="horizontal" flexItem />
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Controller
                    name="field_data.default_apply"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <input
                            {...field}
                            type="checkbox"
                            checked={field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                            className="ml-3 mr-2"
                          />
                        }
                        label={
                          <Typography
                            sx={{ fontSize: "0.9rem", fontWeight: 400 }}
                          >
                            Default apply on form
                          </Typography>
                        }
                      />
                    )}
                  />
                  <Box>
                    {loadingCustomField ? (
                      <LoadingButton size="small" loading variant="contained">
                        Create
                      </LoadingButton>
                    ) : (
                      <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        color="primary"
                      >
                        Create
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        open={deleteFieldDilog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDelteFieldDilog}
      >
        <DialogTitle>Sure you want to delete this field</DialogTitle>
        <DialogActions>
          <Button size="small" variant="outlined" color="primary">
            Edit
          </Button>

          {loader ? (
            <LoadingButton size="small" loading variant="contained">
              Delete
            </LoadingButton>
          ) : (
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={() => handleDeleteCustomField(deleteFieldId)}
            >
              Delete
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Drawer
        anchor="right"
        open={openFilterDrawer}
        onClose={toggleDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: 320,
            p: "1rem",
            ...pageScrollStyle,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: 500, fontSize: "1rem" }}
            >
              Filters
            </Typography>
            <Button
              variant="text"
              size="small"
              onClick={handleResetFiltersCustomField}
              disabled={!isFilterApplied}
              endIcon={<CloseRounded />}
            >
              Reset
            </Button>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                Filter by Creation Date
              </Typography>
              <DateCalendar
                value={filterDateValue}
                onChange={handleDateChange}
                sx={{ width: "100%" }}
              />
            </Box>
          </Box>

          <Box sx={{ mt: "auto", pt: 2 }}>
            <LoadingButton
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleApplyFilter}
              disabled={!hasActiveFilters}
              loading={loader}
            >
              Apply Filters
            </LoadingButton>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default CustomeFields;
