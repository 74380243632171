import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Divider,
  MenuItem,
} from "@mui/material";
import { motion } from "framer-motion";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import RetroGridBg from "../RetroGridBg";
import { companyLogo } from "../../static/staticdata";
import LawyerGroupImg from "../../assets/lawyer-group.webp";
import { backend_api } from "../../static/server";

const industryTypes = [
  "Law Firm",
  "Corporate Legal Department",
  "Government Agency",
  "Non-Profit Legal Services",
  "Solo Practice",
  "Legal Technology",
  "Other",
];

const CreateFirmPage = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      firmName: "",
      industryType: "",
      addressLine1: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      primaryPhone: "",
      email: "",
      contactPersonName: "",
      contactPersonRole: "",
    },
  });

  const handleCreateFirm = async (data) => {
    setError("");
    try {
      const response = await axios.post(
        `${backend_api}admin/create-firm/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        navigate("/admin/firms");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.message || "An error occurred");
      } else {
        setError("Network or server error. Please try again.");
      }
      console.error("Create firm error", error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col-reverse 800px:flex-row overflow-hidden">
      {/* Left Side: Form Section */}
      <motion.div
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        className="w-full 800px:w-1/2 bg-white flex flex-col justify-center items-center"
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            position: "relative",
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              position: "relative",
              zIndex: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.1rem",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 4,
              }}
            >
              <Box sx={{ height: "2rem" }}>
                <img
                  src={companyLogo}
                  alt="Company Logo"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>

              <Typography
                variant="body1"
                color="primary"
                sx={{ fontSize: "1.5rem", fontWeight: "500" }}
              >
                Create New Firm
              </Typography>
            </Box>
            <Box sx={{ position: "relative", zIndex: 5 }}>
              <form
                onSubmit={handleSubmit(handleCreateFirm)}
                className="space-y-4 w-full max-w-sm"
              >
                <Controller
                  name="firmName"
                  control={control}
                  rules={{ required: "Firm name is required" }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      size="small"
                      fullWidth
                      label="Firm Name"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />

                <Controller
                  name="industryType"
                  control={control}
                  rules={{ required: "Industry type is required" }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      select
                      size="small"
                      fullWidth
                      label="Industry Type"
                      error={!!error}
                      helperText={error?.message}
                    >
                      {industryTypes.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />

                <Controller
                  name="addressLine1"
                  control={control}
                  rules={{ required: "Address is required" }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      size="small"
                      fullWidth
                      label="Address"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />

                <Box
                  sx={{
                    display: "grid",
                    gap: 2,
                    gridTemplateColumns: "1fr 1fr",
                  }}
                >
                  <Controller
                    name="city"
                    control={control}
                    rules={{ required: "City is required" }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="City"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />

                  <Controller
                    name="state"
                    control={control}
                    rules={{ required: "State is required" }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="State"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Box>

                <Box
                  sx={{
                    display: "grid",
                    gap: 2,
                    gridTemplateColumns: "1fr 1fr",
                  }}
                >
                  <Controller
                    name="zipCode"
                    control={control}
                    rules={{ required: "ZIP code is required" }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="ZIP Code"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />

                  <Controller
                    name="country"
                    control={control}
                    rules={{ required: "Country is required" }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Country"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Box>

                <Controller
                  name="primaryPhone"
                  control={control}
                  rules={{
                    required: "Phone number is required",
                    pattern: {
                      value: /^\d{10}$/,
                      message: "Invalid phone number",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      size="small"
                      fullWidth
                      label="Phone Number"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />

                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Invalid email address",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      size="small"
                      fullWidth
                      label="Email"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />

                <Box
                  sx={{
                    display: "grid",
                    gap: 2,
                    gridTemplateColumns: "1fr 1fr",
                  }}
                >
                  <Controller
                    name="contactPersonName"
                    control={control}
                    rules={{ required: "Contact person name is required" }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Contact Person"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />

                  <Controller
                    name="contactPersonRole"
                    control={control}
                    rules={{ required: "Role is required" }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Role"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Box>

                {error && <p className="text-red-700">{error}</p>}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={() => navigate("/dashboard")}
                    sx={{ fontSize: "1rem" }}
                    size="small"
                  >
                    Cancel
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{ fontSize: "1rem" }}
                    size="small"
                  >
                    Create Firm
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              overflow: "hidden",
              zIndex: 1,
              top: 0,
              left: 0,
            }}
          >
            <RetroGridBg />
          </Box>
        </Box>
      </motion.div>

      {/* Right Side: Image Section */}
      <motion.div
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        className="w-full 800px:w-1/2 relative text-white flex items-center justify-center flex-col p-5"
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            bgcolor: "#004242",
            borderRadius: "3rem",
          }}
        >
          <Box sx={{ height: "20rem" }}>
            <img
              src={LawyerGroupImg}
              alt="Lawyer Group"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
            />
          </Box>

          <div className="relative z-10 text-center mt-4">
            <h2 className="text-4xl font-bold">Create New Firm</h2>
            <p className="mt-4 text-lg">
              Add a new firm to your platform with all necessary details
            </p>
          </div>
        </Box>
      </motion.div>
    </div>
  );
};

export default CreateFirmPage;
