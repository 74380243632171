import React, { Suspense } from "react";
import "./App.css";
import Layout from "./components/Layout";
import SuspanceLoading from "./components/SuspanceLoading";
import ProtectedRoutes from "./components/ProtectedRoutes";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function App() {
  return (
    <Layout>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Suspense fallback={<SuspanceLoading />}>
          <ProtectedRoutes />
        </Suspense>
      </LocalizationProvider>
    </Layout>
  );
}

export default App;
