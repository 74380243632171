import React, { useState } from "react";
import {
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Box,
  Divider,
} from "@mui/material";
import { motion } from "framer-motion";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { backend_api } from "../../static/server";
import { VisibilityOffRounded, VisibilityRounded } from "@mui/icons-material";
import LoginIcon from "@mui/icons-material/Login";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import RetroGridBg from "../RetroGridBg";
import { companyLogo } from "../../static/staticdata";
import LawyerGroupImg from "../../assets/lawyer-group.webp";
import {
  setLawerId,
  setToken,
  setUser,
  setUserId,
} from "../../redux/Apps/users/userSlice";
import { LoadingButton } from "@mui/lab";

const SignupPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [encryptedKey, setEncryptedKey] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOtpLoading, setIsOtpLoading] = useState(false);

  const handleLogin = async () => {
    setError("");
    setIsLoading(true);
    try {
      const body = new URLSearchParams();
      body.append("email", email);
      body.append("password", password);

      const response = await axios.post(`${backend_api}lawer/login/`, body, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      const responseData = response?.data?.data;
      if (responseData?.user) {
        dispatch(setToken(responseData.user.token));
        dispatch(setUser(responseData.user));
        dispatch(setLawerId(responseData.id));
        dispatch(setUserId(responseData.user.id));
        navigate("/dashboard");
        localStorage.setItem(
          "UserId",
          JSON.stringify({
            token: responseData.user.token,
            emailId: responseData.user.email,
          })
        );
      } else if (responseData?.encrypt_key) {
        setEncryptedKey(responseData.encrypt_key);
      } else {
        setError("Unexpected response structure");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.message || "An error occurred");
      } else {
        setError("Network or server error. Please try again.");
      }
      console.error("Login error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOtpVerify = async () => {
    setError("");
    setIsOtpLoading(true);
    try {
      const body = new URLSearchParams();
      body.append("email", email);
      body.append("otp", otp);
      body.append("encrypted_key", encryptedKey);

      const response = await axios.post(
        `${backend_api}lawer/otp-verify`,
        body,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      const responseData = response?.data?.data;
      if (responseData?.user) {
        dispatch(setToken(responseData.user.token));
        dispatch(setUser(responseData.user));
        navigate("/dashboard");
        localStorage.setItem(
          "UserId",
          JSON.stringify({
            token: responseData.user.token,
            emailId: responseData.user.email,
          })
        );
      } else {
        setError("Unexpected response format");
        console.error("Unexpected response structure", response.data);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setError(
          error.response.data.message ||
            "An error occurred during OTP verification"
        );
      } else {
        setError("Network or server error. Please try again.");
      }
      console.error("OTP Verification Error", error);
    } finally {
      setIsOtpLoading(false);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <div className="min-h-screen flex flex-col-reverse 800px:flex-row overflow-hidden">
      {/* Left Side: Form Section */}
      <motion.div
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        className="w-full 800px:w-1/2 bg-white flex flex-col justify-center items-center"
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            position: "relative",
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              position: "relative",
              zIndex: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.1rem",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 4,
              }}
            >
              <Box sx={{ height: "2rem" }}>
                <img
                  src={companyLogo}
                  alt="Company Logo"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  onError={(e) => {
                    e.onerror = null;
                    e.target.src = require("../../assets/nav-lexmom.webp");
                  }}
                />
              </Box>

              <Typography
                variant="body1"
                color="primary"
                sx={{ fontSize: "1.5rem", fontWeight: "500" }}
              >
                Log In
              </Typography>
            </Box>
            <Box sx={{ position: "relative", zIndex: 5 }}>
              <form className="space-y-4 w-full max-w-sm">
                <TextField
                  size="small"
                  fullWidth
                  label="Email"
                  type="email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  size="small"
                  fullWidth
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                          color="primary"
                        >
                          {showPassword ? (
                            <VisibilityOffRounded />
                          ) : (
                            <VisibilityRounded />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {encryptedKey ? (
                  <>
                    <TextField
                      label="OTP"
                      size="small"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                    <p className="text-red-700">{error}</p>
                    <LoadingButton
                      onClick={handleOtpVerify}
                      loading={isOtpLoading}
                      loadingPosition="start"
                      startIcon={<VerifiedUserIcon />}
                      variant="contained"
                      fullWidth
                      sx={{ p: "0.4rem", fontSize: "1rem" }}
                    >
                      Verify OTP
                    </LoadingButton>
                  </>
                ) : (
                  <>
                    <p className="text-red-700">{error}</p>
                    <LoadingButton
                      onClick={handleLogin}
                      loading={isLoading}
                      loadingPosition="start"
                      endIcon={<LoginIcon />}
                      variant="contained"
                      fullWidth
                      sx={{ p: "0.4rem", fontSize: "1rem" }}
                    >
                      Login
                    </LoadingButton>
                  </>
                )}
              </form>
              <Divider
                sx={{
                  "&::before, &::after": { borderColor: "primary.main" },
                  fontSize: "0.9rem",
                  mt: "1rem",
                }}
                orientation="horizontal"
                flexItem
              >
                or
              </Divider>
              <Box sx={{ display: "flex", gap: "0.2rem" }}>
                <Typography
                  sx={{
                    fontSize: "0.9rem ",
                  }}
                >
                  Don't have an account?
                </Typography>
                <Typography
                  onClick={() => navigate("/sign-up")}
                  sx={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "0.9rem ",
                  }}
                >
                  Sign up
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              overflow: "hidden",
              zIndex: 1,
              top: 0,
              left: 0,
            }}
          >
            <RetroGridBg />
          </Box>
        </Box>
      </motion.div>

      {/* Right Side: Video/Photo Section */}
      <motion.div
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        className="w-full 800px:w-1/2 relative  text-white flex items-center justify-center flex-col  p-5 "
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            bgcolor: "#004242",
            borderRadius: "3rem",
          }}
        >
          <Box sx={{ height: "20rem" }}>
            <img
              src={LawyerGroupImg}
              alt="Company Logo"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("../../assets/no-preview.webp");
              }}
            />
          </Box>

          {/* Overlay Information */}
          <div className="relative z-10 text-center mt-4">
            <h2 className="text-4xl font-bold">Welcome to Our Platform</h2>
            <p className="mt-4 text-lg">
              Join us and enjoy exclusive features designed just for you!
            </p>
          </div>
        </Box>
      </motion.div>
    </div>
  );
};

export default SignupPage;
