import { Box, Grid, Skeleton, Stack } from "@mui/material";
import React from "react";

function TemplateSkeleton({ GridMdRowCount }) {
  const skeletonCards = Array(6).fill(null);

  return (
    <Box
      sx={{
        width: "100%",
        p: { xs: "0.75rem", sm: "1rem", md: "1.25rem" },
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={{ xs: 2, sm: 6, md: 6 }}>
          {skeletonCards?.map((_, index) => (
            <Grid item xs={12} md={GridMdRowCount||3} key={index}>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
              >
                {/* Title section with icon */}
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "0.4rem" }}
                >
                  <Skeleton variant="circular" width={24} height={24} />
                  <Skeleton variant="text" width={120} height={24} />
                </Box>

                {/* Card content */}
                <Box
                  sx={{
                    border: "1px solid #DADADA",
                    borderRadius: "0.625rem",
                    height: "12rem",
                    position: "relative",
                  }}
                >
                  {/* Description area */}
                  <Box sx={{ p: "1rem" }}>
                    <Skeleton variant="text" sx={{ fontSize: "0.9rem" }} />
                    <Skeleton variant="text" sx={{ fontSize: "0.9rem" }} />
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "0.9rem" }}
                      width="80%"
                    />
                  </Box>

                  {/* Practice area footer */}
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      width: "100%",
                      borderTop: "1px solid #DADADA",
                      height: "2rem",
                      display: "flex",
                      alignItems: "center",
                      gap: "0.4rem",
                      px: "0.5rem",
                    }}
                  >
                    <Skeleton variant="text" width={140} height={20} />
                  </Box>

                  {/* Status badge */}
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "2.2rem",
                      right: "0.2rem",
                    }}
                  >
                    <Skeleton variant="rounded" width={40} height={20} />
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default TemplateSkeleton;
