import { lazy } from "react";
import CustomeFields from "./components/CustomeFields";
import CaseTemplate from "./components/LawyerCompo/Cases/CaseTemplate";
import SignUpForm from "./components/Auth/SignUpForm";
import CreateFirmForm from "./components/Auth/CreateFirmForm";

const ProfilePage = lazy(() => import("./pages/LawyerPages/ProfilePage"));
const HomePage = lazy(() => import("./pages/LandingPages/HomePage"));
const SignupPage = lazy(() => import("./pages/SignupPage"));

// <-------------------------------lawyer pages all routes import here ---------------------------------------------->
const DashboardPage = lazy(() => import("./pages/LawyerPages/DashboardPage"));
const TeamPage = lazy(() => import("./pages/LawyerPages/TeamMembers/TeamPage"));
const ContactPage = lazy(() =>
  import("./pages/LawyerPages/Contact/ContactPage")
);
const ViewContactPage = lazy(() =>
  import("./pages/LawyerPages/Contact/ViewContactPage")
);
const EditContactPage = lazy(() =>
  import("./pages/LawyerPages/Contact/EditContactPage")
);

const TaskPage = lazy(() => import("./pages/LawyerPages/Task/TaskPage"));
const TaskListPage = lazy(() =>
  import("./pages/LawyerPages/Task/TaskListPage")
);
const TaskTypePage = lazy(() =>
  import("./pages/LawyerPages/Task/TaskTypePage")
);
const CasesPage = lazy(() => import("./pages/LawyerPages/Cases/CasesPage"));

const CalendarPage = lazy(() =>
  import("./pages/LawyerPages/Calendar/CalendarPage")
);

const ActivitiesPage = lazy(() =>
  import("./pages/LawyerPages/Activities/ActivitiesPage")
);
const BillingPage = lazy(() =>
  import("./pages/LawyerPages/Billing/BillingPage")
);
const AccountPage = lazy(() =>
  import("./pages/LawyerPages/Accounts/AccountPage")
);
const DocumentPage = lazy(() =>
  import("./pages/LawyerPages/Documents/DocumentPage")
);
const DocumentTemplatePage = lazy(() =>
  import("./pages/LawyerPages/Documents/DocumentTemplatePage")
);
const DocumentCategory = lazy(() =>
  import("./pages/LawyerPages/Documents/DocumentCategory")
);

const Communications = lazy(() =>
  import("./pages/LawyerPages/Communications/CommunicationPage")
);
const ReportPage = lazy(() => import("./pages/LawyerPages/Reports/ReportPage"));
const SettingPage = lazy(() =>
  import("./pages/LawyerPages/Settings/SettingPage")
);

export const routes = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/dashboard/profile",
    element: <ProfilePage />,
  },
  {
    path: "/dashboard",
    element: <DashboardPage />,
  },
  {
    path: "/dashboard/team-members",
    element: <TeamPage />,
  },
  {
    path: "/dashboard/calendars",
    element: <CalendarPage />,
  },
  {
    path: "/login",
    element: <SignupPage />,
  },
  {
    path: "/sign-up",
    element: <SignUpForm />,
  },
  {
    path: "/create-firm",
    element: <CreateFirmForm />,
  },
  {
    path: "/dashboard/contact-details",
    element: <ContactPage />,
  },
  {
    path: "/dashboard/contact-details/:contactId",
    element: <ViewContactPage />,
  },
  {
    path: "/dashboard/contact-details/edit-contact-details/:contactId",
    element: <EditContactPage />,
  },
  {
    path: "/dashboard/tasks",
    element: <TaskPage />,
  },
  {
    path: "/dashboard/task/lists",
    element: <TaskListPage />,
  },
  {
    path: "/dashboard/task/type",
    element: <TaskTypePage />,
  },
  {
    path: "/dashboard/matter",
    element: <CasesPage />,
  },
  {
    path: "/dashboard/matter-template",
    element: <CaseTemplate />,
  },
  {
    path: "/dashboard/activities",
    element: <ActivitiesPage />,
  },
  {
    path: "/dashboard/bills",
    element: <BillingPage />,
  },
  {
    path: "/dashboard/bank_accounts",
    element: <AccountPage />,
  },
  {
    path: "/dashboard/documents",
    element: <DocumentPage />,
  },
  {
    path: "/dashboard/documents/templates",
    element: <DocumentTemplatePage />,
  },
  {
    path: "/dashboard/documents/categories",
    element: <DocumentCategory />,
  },
  {
    path: "/dashboard/communications",
    element: <Communications />,
  },
  {
    path: "/dashboard/reports",
    element: <ReportPage />,
  },
  {
    path: "/dashboard/settings",
    element: <SettingPage />,
  },

  {
    path: "/dashboard/custom_fields",
    element: <CustomeFields />,
  },
];
