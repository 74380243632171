import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { backend_api } from "../../../static/server";
import { getAllFilterContacts } from "../../../redux/Apps/Lawyerapp/ContactSlice/ContactSlice";
import {
  createCaseTemplate,
  getCaseTemplate,
  getPracticeArea,
  update,
} from "../../../redux/Apps/Lawyerapp/CaseSlice/CaseSlice";
import { toast } from "react-toastify";
import { pageScrollStyle } from "../../../styles/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getAllCustomField } from "../../../redux/Apps/Lawyerapp/CustomFieldSlice";
import dayjs from "dayjs";
import { getAllTaskList } from "../../../redux/Apps/Lawyerapp/TaskSlice/TaskSlice";
import { NoDataOptionField } from "../../../static/staticdata";
import noOption from "../../../assets/no-option-found.webp";
import { AddRounded } from "@mui/icons-material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddCaseTemplate = ({
  handleCloseAddTemplateForm,
  isEditTempMode,
  editTempId,
  handleCustomFieldForm,
}) => {
  const dispatch = useDispatch();
  const [activeFormIndex, setActiveFormIndex] = useState(0);
  const [selectedCustomFields, setSelectedCustomFields] = useState([]); // Selected custom field
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { token } = useSelector((data) => data.user);
  const { filteredContact, allPracticeArea, allCustomField, allTaskList } =
    useSelector((state) => ({
      filteredContact: state?.contact?.filteredContact,
      allPracticeArea: state?.case?.allPracticeArea,
      allCustomField: state?.customField?.allCustomField,
      allTaskList: state?.task?.allTaskList,
    }));
  const isDialogOpen = sessionStorage.getItem("addTemplateFormOpen");

  useEffect(() => {
    dispatch(
      getPracticeArea({
        url: `${backend_api}case/case-type/?case_service_type=practice_area`,
        token: token,
      })
    );
    dispatch(
      getAllFilterContacts({
        url: `${backend_api}contact/retrieve/`,
        token,
      })
    );
    dispatch(
      getAllCustomField({
        url: `${backend_api}custom-field/?field_for=case`,
        token: token,
      })
    );
    dispatch(
      getAllTaskList({
        url: `${backend_api}task/task-list/`,
        token: token,
      })
    );
  }, [dispatch, token]);

  const savedValues = useMemo(() => {
    const stored = sessionStorage?.getItem("CaseTempData")
      ? JSON.parse(sessionStorage?.getItem("CaseTempData"))
      : null;

    return stored;
  }, []);

  const defaultValues = {
    template_name: "",
    default_use_in_case_form: false,
    template_responsible_attorney: "",
    template_originating_attorney: "",
    template_description: "",
    practice_area: "",
    template_case_status: "",
    template_location: "",
    template_permissions: "",
    custom_fields: [],
    task_list: null,
    custom_fields_data: [],
    billable: false,
    billable_method: "",
    // "under_lawer": "",
    // "created_by": "",
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    getValues,
    watch,
  } = useForm({
    defaultValues: savedValues || defaultValues,
  });
  const formValues = watch();
  useEffect(() => {
    sessionStorage.setItem("CaseTempData", JSON.stringify(formValues));
  }, [formValues]);

  useEffect(() => {
    if (savedValues) {
      Object.keys(savedValues)?.forEach((key) => {
        setValue(key, savedValues[key]);
      });
    }
  }, [savedValues, setValue]);

  const custom_fields = watch("custom_fields");

  const handleNext = () => {
    const currentForm = forms[activeFormIndex];
    const hasErrors = currentForm.fields.some((field) => errors[field]);

    if (!hasErrors) {
      setActiveFormIndex((prev) => prev + 1);
    }
  };

  const handleCustomFieldSelectionChange = (event, selectedOptions) => {
    event.preventDefault();

    // Get current custom_fields_data
    const currentFieldsData = getValues("custom_fields_data") || [];

    // Create a map of existing data for quick lookup
    const existingDataMap = new Map(
      currentFieldsData?.map((item) => [item?.id, item])
    );

    // Create ordered arrays for both fields and their data
    const orderedFieldIds = selectedOptions?.map((option) => option?.id);
    const orderedFieldsData = selectedOptions?.map((option) => {
      return existingDataMap?.get(option?.id) || { id: option?.id, value: "" };
    });

    // Update form values while maintaining order
    setValue("custom_fields", orderedFieldIds, { shouldValidate: true });
    setValue("custom_fields_data", orderedFieldsData);
    setSelectedCustomFields(selectedOptions);

    // Update sessionStorage with ordered data
    const currentValues = getValues();
    sessionStorage.setItem(
      "CaseTempData",
      JSON.stringify({
        ...currentValues,
        custom_fields: orderedFieldIds,
        custom_fields_data: orderedFieldsData,
      })
    );
  };
  useEffect(() => {
    if (isDialogOpen && isEditTempMode) {
      const editData = JSON.parse(sessionStorage?.getItem("editCaseTemp"));
      console.log(editData);
      if (editData) {
        if (editData?.custom_fields && editData?.custom_fields?.length > 0) {
          // Get custom fields in the correct order
          const orderedCustomFields = editData?.custom_fields
            ?.map((fieldId) =>
              allCustomField?.find((field) => field?.id === fieldId)
            )
            ?.filter(Boolean);

          setSelectedCustomFields(orderedCustomFields);
          setValue("custom_fields", editData?.custom_fields);

          if (editData?.custom_fields_data) {
            // Ensure custom_fields_data maintains the same order as custom_fields
            const orderedFieldsData = editData?.custom_fields?.map(
              (fieldId) => {
                return (
                  editData?.custom_fields_data?.find(
                    (data) => data?.id === fieldId
                  ) || { id: fieldId, value: "" }
                );
              }
            );
            setValue("custom_fields_data", orderedFieldsData);
          }
        }
        // Handle task list
        if (editData?.task_list) {
          setValue("task_list", editData?.task_list);
        }

        // Handle task list
        if (editData?.task_list) {
          setValue("task_list", editData?.task_list);
        }
        // Set remaining fields
        Object.keys(editData)?.forEach((key) => {
          setValue(key, editData[key]);
        });
      }
    }
  }, [setValue, allCustomField, isDialogOpen]);

  const handleClearForm = () => {
    sessionStorage.clear();
    setActiveFormIndex(0);
    reset(defaultValues);
  };
  const ListboxComponent = ({
    btnText,
    handleClikListBtn,
    children,
    ...other
  }) => (
    <Box sx={{ position: "relative" }}>
      <ul
        {...other}
        style={{
          maxHeight: "12.5rem",
          overflowY: "auto",
          margin: 0,
          padding: 0,
        }}
      >
        {children}
      </ul>
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          borderTop: "1px solid #e5e5e5",
          backgroundColor: "white",
          padding: "8px",
          display: "flex",
          justifyContent: "flex-end",
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleClikListBtn();
          }}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          variant="text"
          size="small"
          color="primary"
          endIcon={<AddRounded />}
        >
          {btnText}
        </Button>
      </Box>
    </Box>
  );

  const forms = useMemo(
    () => [
      {
        title: "Template information & Responsible Attorneys ",
        fields: [
          "template_name",
          "default_use_in_case_form",
          "template_responsible_attorney",
          "template_originating_attorney",
        ],
        allFields: (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Box sx={{ flex: 1 }}>
                <Controller
                  name="template_name"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      label="Template Name"
                      fullWidth
                      error={!!errors.template_name}
                      required
                    />
                  )}
                />
                {errors?.template_name && (
                  <Typography
                    variant="body1"
                    color="error"
                    sx={{ fontSize: "0.8rem" }}
                  >
                    This field is required
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #c5c5c7",
                  borderRadius: "0.2rem",
                  px: "0.5rem",
                  flex: 0.96,
                  height: "2.5rem",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontSize: "0.8rem", fontWeight: "500" }}
                >
                  Apply default matter form template
                </Typography>
                <Controller
                  fullWidth
                  name="default_use_in_case_form"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                      sx={{
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          color: "#00B3B0",
                        },
                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                          {
                            backgroundColor: "#00B3B0",
                          },
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Controller
                name="template_responsible_attorney"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    size="small"
                    fullWidth
                    options={[]}
                    // getOptionLabel={(option) => option?.name || ""}
                    value={field.value || ""}
                    onChange={(_, newValue) =>
                      field.onChange(newValue ? newValue.id : null)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Responsible Attorney"
                        size="small"
                        fullWidth
                        disabled
                      />
                    )}
                  />
                )}
              />
              <Controller
                name="template_originating_attorney"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    size="small"
                    fullWidth
                    disablePortal
                    options={[]}
                    // getOptionLabel={(option) => option?.name || ""}
                    value={field.value || ""}
                    onChange={(_, newValue) =>
                      field.onChange(newValue ? newValue.id : null)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Originating Attorney"
                        size="small"
                        fullWidth
                        disabled
                      />
                    )}
                  />
                )}
              />
            </Box>
          </Box>
        ),
      },
      {
        title: "Template Matter Details",
        fields: [
          "template_description",
          "practice_area",
          "template_case_status",
          "template_location",
          "template_permissions",
        ],
        allFields: (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Box sx={{ flex: 1 }}>
                <Controller
                  name="template_description"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      multiline
                      rows={5}
                      fullWidth
                      label="Matter Description"
                      error={errors?.template_description}
                      required
                    />
                  )}
                />
                {errors?.template_description && (
                  <Typography
                    variant="body1"
                    color="error"
                    sx={{ fontSize: "0.8rem" }}
                  >
                    This field is required
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <Controller
                  name="practice_area"
                  control={control}
                  render={({ field }) => (
                    <FormControl size="small" fullWidth>
                      <Autocomplete
                        size="small"
                        fullWidth
                        options={allPracticeArea || []}
                        getOptionLabel={(option) => option?.name || ""}
                        value={
                          allPracticeArea?.find(
                            (option) => option?.id === field?.value
                          ) || null
                        }
                        onChange={(_, newValue) =>
                          field.onChange(newValue ? newValue?.id : null)
                        }
                        isOptionEqualToValue={(option, value) =>
                          option?.id === value?.id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Practice Area"
                            size="small"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  )}
                />
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <Controller
                    name="template_case_status"
                    control={control}
                    render={({ field }) => (
                      <FormControl size="small" fullWidth>
                        <InputLabel sx={{ bgcolor: "#fff", px: "0.3rem" }}>
                          Matter Status
                        </InputLabel>
                        <Select {...field} defaultValue="">
                          <MenuItem value="open">Open</MenuItem>
                          <MenuItem value="pending">Pending</MenuItem>
                          <MenuItem value="closed">Closed</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Controller
                    name="template_location"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Location"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Controller
                    name="template_permissions"
                    control={control}
                    render={({ field }) => (
                      <FormControl size="small" fullWidth>
                        <InputLabel
                          id="case_stage-label"
                          sx={{ bgcolor: "#fff", px: "0.3rem" }}
                        >
                          Template Permission
                        </InputLabel>
                        <Select
                          {...field}
                          labelId="case_stage-label"
                          defaultValue=""
                        >
                          <MenuItem value="user">Current User</MenuItem>
                          <MenuItem value="firm">Everyone in Firm</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        ),
      },
      {
        title: "Custom Fields & Task List",
        fields: ["custom_fields", "task_list"],
        allFields: (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              {/* Custom Fields Selector */}
              <Controller
                name="custom_fields"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    fullWidth
                    limitTags={4}
                    options={allCustomField || []}
                    getOptionLabel={(option) => option?.field_name || ""}
                    value={selectedCustomFields}
                    onChange={handleCustomFieldSelectionChange}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Custom Field"
                        size="small"
                        fullWidth
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            bgcolor: "#fff",
                            px: "0.3rem",
                          },
                        }}
                      />
                    )}
                    renderTags={(tagValues, getTagProps) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "nowrap",
                          overflowX: "auto",
                          maxHeight: "2.5rem",
                          maxWidth: "18rem",
                        }}
                      >
                        {tagValues?.map((option, index) => (
                          <Chip
                            size="small"
                            key={option?.id}
                            label={option?.field_name}
                            {...getTagProps({ index })}
                          />
                        ))}
                      </Box>
                    )}
                    noOptionsText={
                      <NoDataOptionField
                        imgSrc={noOption}
                        text={
                          "Oops! Looks like there are no Custom Field to display"
                        }
                        btnText={"Custom Field"}
                        handleClickNoData={handleCustomFieldForm}
                      />
                    }
                    ListboxComponent={(props) => (
                      <ListboxComponent
                        {...props}
                        btnText="Add Custom Field"
                        handleClikListBtn={handleCustomFieldForm}
                      />
                    )}
                  />
                )}
              />
              {/* Task List Selector */}
              <Controller
                name="task_list"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    fullWidth
                    options={allTaskList || []}
                    getOptionLabel={(option) => option.task_list_name || ""}
                    value={
                      field?.value
                        ? allTaskList?.find(
                            (item) => item?.id === field?.value
                          ) || null
                        : null
                    }
                    onChange={(_, newValue) =>
                      field?.onChange(newValue?.id || null)
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Template Task List"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                )}
              />
            </Box>

            {/* Custom Fields Data */}
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "1rem",
                width: "100%",
                maxHeight: "8rem",
                py: "0.5rem",
                overflow: "auto",
                ...pageScrollStyle,
              }}
            >
              {custom_fields && custom_fields?.length > 0 ? (
                custom_fields?.map((fieldId, i) => {
                  const fieldData = allCustomField?.find(
                    (field) => field.id === fieldId
                  );
                  if (!fieldData) return null;
                  return (
                    <Box
                      key={fieldData.id}
                      sx={{
                        flex: {
                          xs: "0 0 100%",
                          sm: "0 0 calc(50% - 0.5rem)",
                        },
                        minWidth: 0,
                      }}
                    >
                      <Controller
                        name={`custom_fields_data.${i}`}
                        control={control}
                        rules={{
                          required: fieldData.field_data?.required,
                        }}
                        defaultValue={{ id: fieldData.id, value: "" }}
                        render={({ field }) => {
                          // Handle select type fields
                          if (fieldData.field_data?.type === "select") {
                            return (
                              <FormControl fullWidth>
                                <Autocomplete
                                  value={field.value?.value || null}
                                  options={fieldData.field_data?.options || []}
                                  onChange={(_, newValue) => {
                                    const fieldValue =
                                      newValue?.value || newValue;
                                    field.onChange({
                                      id: fieldData.id,
                                      value: fieldValue,
                                    });
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={fieldData.field_name}
                                      size="small"
                                      required={fieldData.field_data?.required}
                                      fullWidth
                                    />
                                  )}
                                />
                              </FormControl>
                            );
                          }

                          // Handle date type fields
                          if (fieldData.field_data?.type === "date") {
                            return (
                              <DatePicker
                                fullWidth
                                value={
                                  field.value?.value
                                    ? dayjs(field.value.value)
                                    : null
                                }
                                onChange={(newValue) => {
                                  const dateValue = newValue
                                    ? newValue.format("YYYY-MM-DD")
                                    : null;
                                  field.onChange({
                                    id: fieldData.id,
                                    value: dateValue,
                                  });
                                }}
                                slotProps={{
                                  textField: {
                                    label: fieldData.field_name,
                                    size: "small",
                                    fullWidth: true,
                                    error: Boolean(
                                      errors?.custom_fields_data?.[i]
                                    ),
                                    helperText:
                                      errors?.custom_fields_data?.[i] &&
                                      `${fieldData.field_name} is required`,
                                  },
                                }}
                              />
                            );
                          }
                          // Handle text and other types
                          return (
                            <TextField
                              {...field}
                              label={fieldData.field_name}
                              type={fieldData.field_data?.type || "text"}
                              size="small"
                              fullWidth
                              value={field.value?.value || ""}
                              required={true}
                              onChange={(e) =>
                                field.onChange({
                                  id: fieldData.id,
                                  value: e.target.value,
                                })
                              }
                            />
                          );
                        }}
                      />
                    </Box>
                  );
                })
              ) : (
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ fontSize: "0.8rem", fontWeight: 400 }}
                >
                  Select custom Field for additional data.
                </Typography>
              )}
            </Box>
          </Box>
        ),
      },
      {
        title: "Billing preferences",
        fields: ["billable", "bill_method"],
        allFields: (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Box
              fullWidth
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid #c5c5c7",
                borderRadius: "0.2rem",
                px: "0.5rem",
                width: "100%",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: "0.8rem", fontWeight: "500" }}
              >
                This matter is billable
              </Typography>
              <Controller
                fullWidth
                name="billable"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switch
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                    sx={{
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: "#00B3B0",
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                        {
                          backgroundColor: "#00B3B0",
                        },
                    }}
                  />
                )}
              />
            </Box>
            <Controller
              name="bill_method"
              control={control}
              render={({ field }) => (
                <FormControl size="small" fullWidth>
                  <InputLabel
                    id="bill_method-label"
                    sx={{ bgcolor: "#fff", px: "0.3rem" }}
                  >
                    Template billing method
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="bill_method-label"
                    defaultValue=""
                  >
                    <MenuItem value="" disabled>
                      select billing method
                    </MenuItem>
                    <MenuItem value="hourly">Hourly</MenuItem>
                    <MenuItem value="flate_rate">Flate Rate</MenuItem>
                    <MenuItem value="contingence_fee">Contingence_fee</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Box>
        ),
      },
      // {
      //   title: "Document folders",
      //   fields: ["folder_name", "folder_category"],
      //   allFields: (
      //     <Box sx={{ display: "flex", gap: "1rem" }}>
      //       <Controller
      //         name="folder_name"
      //         control={control}
      //         render={({ field }) => (
      //           <TextField
      //             {...field}
      //             size="small"
      //             fullWidth
      //             label="Folder Name"
      //           />
      //         )}
      //       />
      //       <Controller
      //         name="folder_category"
      //         control={control}
      //         render={({ field }) => (
      //           <Autocomplete
      //             {...field}
      //             fullWidth
      //             options={[]}
      //             // getOptionLabel={(option) => option?.name || ""}
      //             value={field.value || ""}
      //             onChange={(_, newValue) =>
      //               field.onChange(newValue ? newValue.id : null)
      //             }
      //             renderInput={(params) => (
      //               <TextField
      //                 {...params}
      //                 label="Category"
      //                 size="small"
      //                 fullWidth
      //               />
      //             )}
      //           />
      //         )}
      //       />
      //     </Box>
      //   ),
      // },
    ],
    [
      allCustomField,
      allPracticeArea,
      allTaskList,
      control,
      custom_fields,
      errors?.custom_fields_data,
      errors?.template_description,
      errors.template_name,
      selectedCustomFields,
    ]
  );
  const validateCustomFields = (customFields, customFieldsData) => {
    const errors = {};

    if (customFields?.length > 0) {
      customFields.forEach((fieldId, index) => {
        const fieldData = allCustomField?.find((field) => field.id === fieldId);
        if (fieldData?.field_data?.required) {
          const fieldValue = customFieldsData?.[index]?.value;
          if (!fieldValue || fieldValue === "") {
            errors[`custom_fields_data.${index}`] = {
              type: "required",
              message: `${fieldData.field_name} is required`,
            };
          }
        }
      });
    }
    return errors;
  };

  const onSubmit = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    // Validate custom fields
    const customFieldErrors = validateCustomFields(
      data.custom_fields,
      data.custom_fields_data
    );
    if (Object.keys(customFieldErrors).length > 0) {
      const customFieldFormIndex = forms.findIndex((form) =>
        form.fields.includes("custom_fields")
      );
      setActiveFormIndex(customFieldFormIndex);
      setIsSubmitting(false);
      return;
    }

    // Find form with errors and set active index
    const allErrors = Object.keys(errors);
    const errorFormIndex = forms.findIndex((form) =>
      form.fields.some((field) => allErrors.includes(field))
    );
    if (errorFormIndex !== -1) {
      setActiveFormIndex(errorFormIndex);
      setIsSubmitting(false);
      return;
    }
    if (activeFormIndex < forms.length - 1) {
      setActiveFormIndex((prev) => prev + 1);
      setIsSubmitting(false);
    } else {
      try {
        if (isEditTempMode && editTempId) {
          await dispatch(
            update({
              url: `${backend_api}case/template-register/${editTempId}/`,
              body: data,
              token: token,
            })
          ).unwrap();
          toast.success("Matter template updated successfully!");
        } else {
          await dispatch(
            createCaseTemplate({
              url: `${backend_api}case/template-register/`,
              body: data,
              token: token,
            })
          ).unwrap();
          toast.success("Matter template created successfully!");
          handleClearForm();
        }

        handleCloseAddTemplateForm();
        handleClearForm();
      } catch (error) {
        toast.error(
          `Failed to ${isEditTempMode ? "update" : "create"} matter template`
        );
      } finally {
        setIsSubmitting(false);
        dispatch(
          getCaseTemplate({
            url: `${backend_api}case/template-register/`,
            token: token,
          })
        );
      }
    }
  };

  useEffect(() => {
    const findFormWithErrors = () => {
      const allErrors = Object.keys(errors);

      // Check each form for errors
      for (let i = 0; i < forms.length; i++) {
        const form = forms[i];

        // Check regular field errors
        const hasFieldError = form.fields?.some((field) =>
          allErrors.includes(field)
        );

        // Check nested field errors (e.g., custom_fields_data.0)
        const hasNestedError = allErrors.some(
          (error) =>
            error.startsWith("custom_fields_data.") &&
            form.fields.includes("custom_fields")
        );

        // Check required custom fields
        const hasCustomFieldError =
          form.fields?.includes("custom_fields") &&
          custom_fields?.some((fieldId, index) => {
            const fieldData = allCustomField?.find(
              (field) => field.id === fieldId
            );
            if (fieldData?.field_data?.required) {
              const fieldValue = watch(`custom_fields_data.${index}.value`);
              return !fieldValue || fieldValue === "";
            }
            return false;
          });

        if (hasFieldError || hasNestedError || hasCustomFieldError) {
          return i;
        }
      }
      return -1;
    };

    const errorFormIndex = findFormWithErrors();
    if (errorFormIndex !== -1 && errorFormIndex !== activeFormIndex) {
      setActiveFormIndex(errorFormIndex);
    }
  }, [errors, activeFormIndex, forms, custom_fields, allCustomField, watch]);

  const steps = [
    {
      label: "Template information & Responsible Attorneys",
      description: "",
    },
    {
      label: "Template Matter Details",
      description: "",
    },
    {
      label: "Custom Field & Task List",
      description:
        "Custom Field is used for other details like an alternative number and any data which you want to save for the contact.",
    },
    {
      label: "Template Billing Preferences",
      description: "",
    },
  ];
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            height: "90vh",
            overflow: "hidden",
            display: "flex",
          }}
        >
          <Box sx={{ width: "25%" }}>
            <Box sx={{ pl: "2rem" }}>
              <Stepper activeStep={activeFormIndex} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel
                      error={forms[index]?.fields?.some(
                        (field) => errors[field]
                      )}
                    >
                      {step.label}
                    </StepLabel>
                    <StepContent>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{ fontSize: "0.7rem" }}
                      >
                        {step.description}
                      </Typography>
                      <Box sx={{ mb: 2 }}>
                        <Button
                          size="small"
                          variant="contained"
                          sx={{ mt: 1, mr: 1 }}
                          onClick={() =>
                            index === steps.length - 1
                              ? handleSubmit(onSubmit)()
                              : handleNext()
                          }
                          disabled={isSubmitting}
                        >
                          {index === steps.length - 1 ? "Submit" : "Next"}
                        </Button>
                        <Button
                          size="small"
                          disabled={index === 0 || isSubmitting}
                          sx={{ mt: 1, mr: 1 }}
                          onClick={() => {
                            setActiveFormIndex((prev) => prev - 1);
                          }}
                        >
                          Back
                        </Button>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                variant="text"
                size="small"
                onClick={() => handleClearForm()}
              >
                Clear form
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              width: "75%",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              pt: "3rem",
              bgcolor: "#f5f5f5",
            }}
          >
            {forms.map((form, index) => (
              <Box
                key={index}
                sx={{
                  width: "60rem",
                  position: "absolute",
                  transition: "transform 0.6s, z-index 0.6s, opacity 0.6s",
                  transform: `scale(${
                    index === activeFormIndex ? 1 : 0.9
                  }) translateY(${index * 50}px)`,
                  zIndex:
                    index === activeFormIndex
                      ? 10
                      : 5 - Math.abs(index - activeFormIndex),
                  opacity: 1,
                  p: "1rem",
                  borderRadius: "1rem",
                  boxShadow: "0px 0px 0.8rem #d5d5d5",
                  bgcolor: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (index !== activeFormIndex) {
                    setActiveFormIndex(index);
                  }
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    color: "#333",
                    mb: 2,
                  }}
                >
                  {form.title}
                </Typography>
                <Box>{form.allFields}</Box>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  {isSubmitting ? (
                    <LoadingButton
                      sx={{ mt: 2 }}
                      size="small"
                      loading
                      variant="contained"
                    >
                      Submit
                    </LoadingButton>
                  ) : (
                    index === activeFormIndex && (
                      <Button
                        type={index === forms.length - 1 ? "submit" : "button"}
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                        onClick={() => {
                          if (index < forms.length - 1) {
                            setActiveFormIndex((prev) => prev + 1);
                          } else {
                            handleSubmit(onSubmit)();
                          }
                        }}
                      >
                        {index === forms.length - 1 ? "Submit" : "Next"}
                      </Button>
                    )
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default AddCaseTemplate;
