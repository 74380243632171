import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Tooltip,
  Collapse,
  Divider,
} from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { DashboardSideMenu } from "../../../static/staticdata";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

const SideNavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openSide, setOpenSide] = useState(false);
  const [openMenus, setOpenMenus] = useState({});

  const handleOpenSlide = () => {
    setOpenSide(!openSide);
  };

  const toggleSubMenu = (menuText) => {
    setOpenMenus((prev) => ({
      ...prev,
      [menuText]: !prev[menuText],
    }));
  };

  const renderSubMenu = (subMenu, parentIsActive) => {
    return subMenu.map((subItem, subIndex) => {
      const isSubActive = location.pathname === subItem.path;

      const subMenuItemContent = (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.75rem",
            px: "1.5rem",
            py: "0.4rem",
            borderRadius: "0.3rem",
            color: isSubActive ? "#00B3B0" : "text.secondary",
            bgcolor: isSubActive ? "#00b3b01a" : "transparent",
            "&:hover": {
              bgcolor: "#00b3b00d",
            },
            transition: "all 0.2s ease",
            fontSize: "0.9rem",
          }}
        >
          <Box
            sx={{
              fontSize: "1rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            {subItem.mIcon}
          </Box>
          {!openSide && (
            <Typography
              variant="body2"
              sx={{
                fontWeight: isSubActive ? 600 : 400,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "0.85rem",
              }}
            >
              {subItem.mText}
            </Typography>
          )}
        </Box>
      );

      return !openSide ? (
        <NavLink
          key={subIndex}
          to={subItem.path}
          style={{
            textDecoration: "none",
            display: "block",
            width: "100%",
          }}
        >
          {subMenuItemContent}
        </NavLink>
      ) : (
        <Tooltip key={subIndex} title={subItem.mText} placement="right">
          <NavLink
            to={subItem.path}
            style={{
              textDecoration: "none",
              display: "block",
              width: "100%",
            }}
          >
            {subMenuItemContent}
          </NavLink>
        </Tooltip>
      );
    });
  };

  const renderMenuItems = () => {
    return DashboardSideMenu?.map((item, i) => {
      const isActive =
        location.pathname === item.path ||
        (item.path === "/dashboard" &&
          location.pathname.endsWith("/dashboard")) ||
        (item.subMenu &&
          item.subMenu.some((subItem) =>
            location.pathname.endsWith(subItem.path)
          ));

      const hasSubMenu = item.subMenu && item.subMenu.length > 0;

      const menuItemContent = (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "0.75rem",
            px: openSide ? "0.5rem" : "0.75rem",
            py: "0.5rem",
            borderRadius: "0.5rem",
            color: isActive ? "#00B3B0" : "text.secondary",
            bgcolor: isActive ? "#00b3b01a" : "transparent",
            "&:hover": {
              bgcolor: "#00b3b00d",
            },
            transition: "all 0.2s ease",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.75rem",
            }}
          >
            <Box
              sx={{
                fontSize: "1.25rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              {item.mIcon}
            </Box>
            {!openSide && (
              <Typography
                variant="body2"
                sx={{
                  fontWeight: isActive ? 600 : 400,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.mText}
              </Typography>
            )}
          </Box>
          {!openSide && hasSubMenu && (
            <Box
              onClick={(e) => {
                e.preventDefault();
                toggleSubMenu(item.mText);
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                transform: openMenus[item.mText]
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
                transition: "transform 0.2s ease",
              }}
            >
              <ExpandMoreRoundedIcon />
            </Box>
          )}
        </Box>
      );

      const menuItem = openSide ? (
        <Tooltip key={i} title={item.mText} placement="right">
          <NavLink
            to={item.path}
            style={{
              textDecoration: "none",
              display: "block",
              width: "100%",
            }}
          >
            {menuItemContent}
          </NavLink>
        </Tooltip>
      ) : (
        <Box key={i} sx={{ width: "100%" }}>
          <NavLink
            to={item.path}
            style={{
              textDecoration: "none",
              display: "block",
              width: "100%",
            }}
          >
            {menuItemContent}
          </NavLink>
          {hasSubMenu && (
            <Collapse in={openMenus[item.mText]} unmountOnExit>
              <Divider orientation="horizontal" flexItem />
              <Box
                sx={{
                  mt: "0.4rem",
                }}
              >
                {renderSubMenu(item.subMenu, isActive)}
              </Box>
              <Divider orientation="horizontal" flexItem />
            </Collapse>
          )}
        </Box>
      );

      return menuItem;
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: openSide ? "4.5rem" : "16rem",
        bgcolor: "background.paper",
        borderRight: "1px solid",
        borderColor: "divider",
        transition: "width 0.3s ease",
        overflow: "hidden",
        position: "sticky",
        top: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 1.5,
          borderBottom: "1px solid",
          borderColor: "divider",
        }}
      >
        {!openSide && (
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              color: "#00B3B0",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            Collabe
          </Typography>
        )}
        <Button
          variant="text"
          color="primary"
          onClick={handleOpenSlide}
          sx={{
            minWidth: "auto",
            ml: openSide ? 0 : "auto",
            color: "#00B3B0",
          }}
        >
          {openSide ? <ChevronRightRoundedIcon /> : <ChevronLeftRoundedIcon />}
        </Button>
      </Box>

      <Box
        sx={{
          overflowY: "auto",
          overflowX: "hidden",
          p: 1,
          display: "flex",
          flexDirection: "column",
          gap: 0.5,
          scrollbarWidth: "thin",
          "&::-webkit-scrollbar": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#0000001a",
            borderRadius: "3px",
          },
        }}
      >
        {renderMenuItems()}
      </Box>
    </Box>
  );
};

export default SideNavBar;
