import { Box } from "@mui/material";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import company_logo from "../assets/nav-lexmom.webp";

const SuspanceLoading = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: ".5rem",
        }}
      >
        <Box sx={{ height: "5rem", width: "10rem" }}>
          <img
            src={company_logo}
            alt="No Preview"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
            onError={(e) => {
              e.onerror = null;
              e.target.src = require("../assets/nav-lexmom.webp");
            }}
          />
        </Box>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#00B3B0" />
              <stop offset="100%" stopColor="#00B3B0" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress size={45} thickness={4} />
      </Box>
    </Box>
  );
};

export default SuspanceLoading;
