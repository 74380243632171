import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

export const createTag = createAsyncThunk(
  "createTag",
  async ({ url, body, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(url, body, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to create tag");
    }
  }
);

export const getAllTags = createAsyncThunk(
  "getAllTags",
  async ({ url, token }, { rejectWithValue }) => {
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });
      return res.data;
    } catch (error) {
      toast.error("Failed to get all tags.");
      return rejectWithValue(error.response?.data || "Error fetching tags");
    }
  }
);

export const getAllRoles = createAsyncThunk(
  "getAllRoles",
  async ({ url }, { rejectWithValue }) => {
    try {
      const res = await axios.get(url);
      return res.data.data;
    } catch (error) {
      toast.error("Failed to get all roles.");
      return rejectWithValue(error.response?.data || "Error fetching roles");
    }
  }
);

export const getAllFilterContacts = createAsyncThunk(
  "getAllFilterContacts",
  async ({ url, token }, { rejectWithValue }) => {
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching contacts");
    }
  }
);

export const getContactDetail = createAsyncThunk(
  "getContactDetail",
  async ({ url, token }, { rejectWithValue }) => {
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return res.data;
    } catch (error) {
      toast.error("Failed to get contact detail.");
      return rejectWithValue(
        error.response?.data || "Error fetching contact detail"
      );
    }
  }
);

export const createContact = createAsyncThunk(
  "createContact",
  async ({ url, body, token }, { rejectWithValue }) => {
    try {
      const res = await axios.post(url, body, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });
      return res.data;
    } catch (error) {
      toast.error("Failed to create contact.");
      return rejectWithValue(error.response?.data || "Error creating contact");
    }
  }
);

// Slice
const contactSlice = createSlice({
  name: "contact",
  initialState: {
    allRoles: null,
    allTags: null,
    filteredContacts: null,
    contactDetail: null,
    loading: false,
    tagLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createContact.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createContact.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createContact.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Error creating contact.";
      })
      .addCase(getAllRoles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.allRoles = action?.payload;
      })
      .addCase(getAllRoles.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload || "Error fetching roles.";
      })
      .addCase(createTag.pending, (state) => {
        state.tagLoading = true;
        state.error = null;
      })
      .addCase(createTag.fulfilled, (state) => {
        state.tagLoading = false;
      })
      .addCase(createTag.rejected, (state, action) => {
        state.tagLoading = false;
        state.error = action.payload || "Fail to create tag.";
      })
      .addCase(getAllTags.pending, (state) => {
        state.tagLoading = true;
        state.error = null;
      })
      .addCase(getAllTags.fulfilled, (state, action) => {
        state.tagLoading = false;
        state.allTags = action.payload;
      })
      .addCase(getAllTags.rejected, (state, action) => {
        state.tagLoading = false;
        state.error = action.payload || "Failed to get tags ";
      })
      .addCase(getAllFilterContacts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllFilterContacts.fulfilled, (state, action) => {
        state.loading = false;
        state.filteredContacts = action.payload;
      })
      .addCase(getAllFilterContacts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Error fetching filtered contacts.";
      })
      .addCase(getContactDetail.pending, (state) => {
        // state.loading = true;
        state.error = null;
      })
      .addCase(getContactDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.contactDetail = action.payload?.data;
      })
      .addCase(getContactDetail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Faild to get Contact Details";
      });
  },
});

export const contactReducer = contactSlice.reducer;
