import {
  Box,
  Grid,
  Typography,
  Stack,
  Pagination,
  Tooltip,
  IconButton,
  Button,
  TextField,
  Dialog,
  Slide,
  Skeleton,
  DialogActions,
  DialogTitle,
  Drawer,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  FormControlLabel,
  Divider,
} from "@mui/material";
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";
import noData from "../../../assets/empty-box.webp";
import React, { useEffect, useState } from "react";
import { CloseRounded, DeleteOutlineRounded } from "@mui/icons-material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import styles, { pageScrollStyle } from "../../../styles/styles";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import AddCaseTemplate from "./AddCaseTemplate";
import { useDispatch, useSelector } from "react-redux";
import {
  getCaseTemplate,
  getEdtiTempData,
  getPracticeArea,
} from "../../../redux/Apps/Lawyerapp/CaseSlice/CaseSlice";
import { backend_api } from "../../../static/server";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { toast } from "react-toastify";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import TemplateSkeleton from "../../TemplateSkeleton";
import { Controller, useForm } from "react-hook-form";
import {
  createCustomField,
  getAllCustomField,
} from "../../../redux/Apps/Lawyerapp/CustomFieldSlice";
import { inputTypes } from "../../CustomeFields";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CaseTemplate = () => {
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  const [addTemplateForm, setAddTemplateForm] = useState(false);
  const [deleteTempId, setDeleteTempId] = useState(null);
  const [deleteLoad, setDeleteLoad] = useState(false);
  const [filterTempDrawer, setFilterTempDrawer] = useState(false);
  const [selectedPracticeArea, setSelectedPracticeArea] = useState(null);
  const [searchTempQuery, setSearchTempQuery] = useState(null);
  const [tempPracticeArea, setTempPracticeArea] = useState(null);
  const [isFilterChanged, setIsFilterChanged] = useState(false);
  const [isEditTempMode, setIsEditTempMode] = useState(false);
  const [editTempId, setEditTempId] = useState(null);
  const [openDeleteTemplateDialog, setopenDeleteTemplateDialog] =
    useState(false);

  // ----- CUSTOM FIELD FORM STATE HERE -----
  const [addFieldOpenDilog, setAddFieldOpenDilog] = useState(false);
  const [optionLenError, setOptionLenError] = useState(false);
  const [optionCount, setOptionCount] = useState(2);
  const { userId, lawerId } = useSelector((state) => ({
    userId: state?.user?.userId,
    lawerId: state?.user?.lawerId,
  }));
  const { loadingCustomField } = useSelector((state) => state?.customField);
  const { token } = useSelector((state) => state.user);
  const {
    allPracticeArea,
    allCaseTemplate,
    editcaseTemplateData,
    loading,
    error,
  } = useSelector((state) => state.case);
  const totalPage = Math.ceil(allCaseTemplate?.length / 4);

  useEffect(() => {
    dispatch(
      getCaseTemplate({
        url: `${backend_api}case/template-register/`,
        token: token,
      })
    );
    dispatch(
      getPracticeArea({
        url: `${backend_api}case/case-type/?case_service_type=practice_area`,
        token: token,
      })
    );
  }, [dispatch]);

  const {
    handleSubmit: handleSubmitCustomFieldForm,
    control: controlCustomFieldForm,
    reset: resetCustomFieldForm,
    watch: watchCustomFieldForm,
    formState: { errors: errorsCustomFieldForm },
  } = useForm({
    defaultValues: {
      created_by: userId,
      under_lawer: lawerId,
      field_for: "case",
      field_name: "",
      field_data: {
        type: "",
        placeholder: "",
        validation: false,
        required: false,
        default_apply: false,
        options: [],
      },
    },
  });

  const field_type = watchCustomFieldForm("field_data.type");

  const handleDeleteOption = (i) => {
    if (optionCount === 2) {
      setOptionLenError(true);
    } else {
      setOptionCount(optionCount - 1);
    }
  };

  const handleCustomFieldForm = () => {
    console.log("customFieldForm");
    setAddFieldOpenDilog(true);
  };

  useEffect(() => {
    const dialogState = sessionStorage.getItem("addTemplateFormOpen");
    if (dialogState === "true") {
      setAddTemplateForm(true);
    }
  }, []);

  // Save the dialog state in sessionStorage whenever it changes
  useEffect(() => {
    sessionStorage.setItem("addTemplateFormOpen", addTemplateForm.toString());
  }, [addTemplateForm]);

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };
  const handleCloseAddTemplateForm = () => {
    sessionStorage.clear();
    setAddTemplateForm(false);
    setIsEditTempMode(false);
  };

  const handleCloseDelteTemplateDialog = () => {
    setopenDeleteTemplateDialog(false);
  };
  const handleOpenDelteTempDialog = (id) => {
    setDeleteTempId(id);
    setopenDeleteTemplateDialog(true);
  };

  const handleDeleteCaseTemplate = async (id) => {
    setDeleteLoad(true);
    try {
      const res = await axios.delete(
        `${backend_api}case/template-register/${id}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res?.status === 204) {
        dispatch(
          getCaseTemplate({
            url: `${backend_api}case/template-register/`,
            token: token,
          })
        );
        toast.success("Matter template deleted successfully");
        return res;
      }
    } catch (error) {
      toast.error("Failed to delete the matter template. Please try again");
    } finally {
      handleCloseDelteTemplateDialog();
      setDeleteLoad(false);
    }
  };
  const handleIsEditTemp = async (id) => {
    setIsEditTempMode(true);
    setEditTempId(id);
    try {
      await dispatch(
        getEdtiTempData({
          url: `${backend_api}case/template-register/${id}/`,
          token: token,
        })
      ).unwrap();
      setAddTemplateForm(true);
    } catch (error) {
      toast.error("Failed to get template data");
    }
  };
  useEffect(() => {
    if (editcaseTemplateData) {
      sessionStorage.setItem(
        "editCaseTemp",
        JSON.stringify(editcaseTemplateData)
      );
    }
  }, [editcaseTemplateData]);

  useEffect(() => {
    const dialogState = sessionStorage.getItem("addTemplateFormOpen");
    const editTemplateData = sessionStorage.getItem("editCaseTemp");
    if (dialogState === "true" || (isEditTempMode && editTemplateData)) {
      setAddTemplateForm(true);
    }
  }, [isEditTempMode]);

  const onSubmitCustomFieldForm = async (data, e) => {
    e.preventDefault();

    if (data?.field_data?.type === "select") {
      const validOptions = data?.field_data?.options.filter(
        (option) => option && option?.trim() !== ""
      );

      if (validOptions?.length < 2) {
        toast.error("Select type fields must have at least 2 options");
        return;
      }

      data.field_data.options = validOptions;
    }

    try {
      await dispatch(
        createCustomField({
          url: `${backend_api}custom-field/`,
          body: data,
          token: token,
        })
      ).unwrap();
      toast.success("Custom Field created successfully");

      // Refresh the list
      await dispatch(
        getAllCustomField({
          url: `${backend_api}custom-field/?field_for=case`,
          token: token,
        })
      ).unwrap();

      resetCustomFieldForm();
      setAddFieldOpenDilog(false);
    } catch (error) {
      console.error(error);
      toast.error(
        error?.message || "Failed to save the field. Please try again."
      );
    }
  };

  const handleTempSearch = (e) => {
    const query = e.target.value;
    setSearchTempQuery(query);

    const url = query
      ? `${backend_api}case/template-register/?name=${encodeURIComponent(
          query
        )}`
      : `${backend_api}case/template-register/`;

    dispatch(
      getCaseTemplate({
        url,
        token,
      })
    );
  };

  const togglefilterTempDrawer = (newOpen) => () => {
    setFilterTempDrawer(newOpen);
  };

  const handleApplyFilters = () => {
    if (tempPracticeArea) {
      dispatch(
        getCaseTemplate({
          url: `${backend_api}case/template-register/?practice_area=${tempPracticeArea?.id}`,
          token: token,
        })
      );
      setSelectedPracticeArea(tempPracticeArea);
      setIsFilterChanged(false);
      togglefilterTempDrawer(false)();
    } else {
      dispatch(
        getCaseTemplate({
          url: `${backend_api}case/template-register/`,
          token: token,
        })
      );
    }
  };

  const handleResetFilters = () => {
    dispatch(
      getCaseTemplate({
        url: `${backend_api}case/template-register/`,
        token: token,
      })
    );
    setTempPracticeArea(null);
    setSelectedPracticeArea(null);
    setIsFilterChanged(false);
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <div className="bg-white rounded-lg items-center shadow-md p-2">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2 className={`${styles.title}`}>Matter Templates</h2>
              <Box className="flex gap-4">
                <Box>
                  <TextField
                    size="small"
                    placeholder="By name"
                    label="Search"
                    value={searchTempQuery}
                    onChange={handleTempSearch}
                    sx={{ width: "15rem", marginRight: "1rem" }}
                  />
                </Box>
                <Button
                  variant="contained"
                  sx={{ textTransform: "capitalize" }}
                  startIcon={<AddRoundedIcon />}
                  onClick={() => setAddTemplateForm(true)}
                  size="small"
                >
                  New Template
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<FilterListRoundedIcon />}
                  onClick={togglefilterTempDrawer(true)}
                >
                  Filter
                </Button>
              </Box>
            </Box>
          </div>
          <div>
            <div className="w-full flex">
              <div className="flex items-start justify-between w-full">
                <Box
                  className="bg-white rounded-lg justify-between h-[85vh] p-2 w-full shadow-sm"
                  sx={{ ...pageScrollStyle, overflow: "auto" }}
                >
                  <Box>
                    {loading ? (
                      <TemplateSkeleton />
                    ) : (
                      <Box sx={{ display: "flex", width: "100%", mb: "1rem" }}>
                        {allCaseTemplate && allCaseTemplate?.length > 0 ? (
                          <Grid
                            item
                            xs={12}
                            container
                            spacing={{ xs: 2, sm: 6, md: 6 }}
                          >
                            {allCaseTemplate?.map((item, i) => (
                              <Grid key={i} item xs={12} md={3}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.5rem",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-start",
                                      gap: "0.4rem",
                                    }}
                                  >
                                    <BusinessCenterRoundedIcon />
                                    <Tooltip title={item?.template_name || "-"}>
                                      <Typography
                                        variant="body1"
                                        sx={{
                                          textTransform: "capitalize",
                                          fontSize: "1rem",
                                          fontWeight: "500",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {item?.template_name || "-"}
                                      </Typography>
                                    </Tooltip>
                                  </Box>
                                  <Box
                                    sx={{
                                      position: "relative",
                                      borderRadius: "0.625rem",
                                      border: "1px solid #DADADA",
                                      p: "0",
                                      "&:hover ": {
                                        boxShadow: "0rem 0rem 0.5rem #0000001a",
                                      },
                                      "&:hover .action-box": {
                                        bottom: "0rem",
                                        transform: "translateY(0)",
                                        opacity: 1,
                                      },
                                      "&::after": {
                                        content: `"${item?.template_case_status}"`,
                                        textTransform: "capitalize",
                                        fontSize: "0.7rem",
                                        fontWeight: 500,
                                        display: "block",
                                        position: "absolute",
                                        bgcolor:
                                          item?.template_case_status === "open"
                                            ? "#4CAF50"
                                            : item?.template_case_status ===
                                              "pending"
                                            ? "#FFC107"
                                            : "#F44336",
                                        color: "#fff",
                                        p: "0.2rem",
                                        borderRadius: "0.5rem",
                                        bottom: "2.2rem",
                                        right: "0.2rem",
                                      },
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        height: "12rem",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Box
                                        sx={{ textAlign: "left", p: "1rem" }}
                                      >
                                        <Typography
                                          sx={{
                                            color: "#606060",
                                            fontSize: "0.9rem",
                                            fontWeight: "400",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {item?.template_description || "-"}
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          borderTop: "1px solid #DADADA",
                                          height: "2rem",
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "0.4rem",
                                          px: "0.5rem",
                                        }}
                                      >
                                        <Typography
                                          variant="body1"
                                          sx={{
                                            color: "#606060",
                                            fontSize: "0.8rem",
                                            fontWeight: 500,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          Practice Area:
                                        </Typography>
                                        <Typography
                                          variant="body1"
                                          sx={{
                                            fontSize: "0.7rem",
                                            fontWeight: 600,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {allPracticeArea?.find(
                                            (area) =>
                                              area?.id === item?.practice_area
                                          )?.name || "-"}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      className="action-box"
                                      sx={{
                                        position: "absolute",
                                        bottom: "-2rem",
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        background: "#FFFFFF40",
                                        boxShadow: "0 0.5rem 1rem 0 #1F268780",
                                        backdropFilter: "blur(4px)",
                                        WebkitBackdropFilter: "blur(4px)",
                                        borderRadius: "0.8rem",
                                        border: "1px solid #FFFFFF2E",
                                        transform: "translateY(1rem)",
                                        opacity: 0,
                                        transition: "all 0.3s ease",
                                        px: "0.5rem",
                                        py: "0.2rem",
                                        gap: "0.5rem",
                                      }}
                                    >
                                      <Button
                                        variant="contained"
                                        size="small"
                                        endIcon={
                                          <BorderColorRoundedIcon
                                            sx={{ fontSize: "0.8rem" }}
                                          />
                                        }
                                        onClick={() => {
                                          handleIsEditTemp(item?.id);
                                        }}
                                      >
                                        Edit
                                      </Button>
                                      <Button
                                        variant="outlined"
                                        size="small"
                                        color="error"
                                        startIcon={<DeleteOutlineRounded />}
                                        onClick={() =>
                                          handleOpenDelteTempDialog(item?.id)
                                        }
                                      >
                                        Delete
                                      </Button>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                            ))}
                          </Grid>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              gap: "1rem",
                              width: "100%",
                              height: "74vh",
                            }}
                          >
                            <Box sx={{ height: "13rem", width: "13rem" }}>
                              <img
                                src={noData}
                                alt="No Preview"
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                  mixBlendMode: "multiply",
                                }}
                                onError={(e) => {
                                  e.onerror = null;
                                  e.target.src = require("../../../assets/empty-box.webp");
                                }}
                              />
                            </Box>
                            <Typography
                              variant="body1"
                              sx={{ textAlign: "center" }}
                            >
                              No Template Available
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        m: { xs: "1rem 0rem", sm: "initial", md: "initial" },
                      }}
                    >
                      <Stack spacing={1}>
                        <Pagination
                          page={activePage}
                          count={totalPage}
                          variant="outlined"
                          shape="rounded"
                          color="primary"
                          onChange={handlePageChange}
                        />
                      </Stack>
                    </Box>
                  </Box>
                </Box>
              </div>
            </div>
          </div>
        </Box>

        <Dialog
          fullScreen
          open={addTemplateForm}
          onClose={handleCloseAddTemplateForm}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              padding: 0,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: "1rem",
              py: "0.5rem",
              bgcolor: "primary.main",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <Typography
                variant="body1"
                color="white.main"
                sx={{ fontSize: "1rem" }}
              >
                {isEditTempMode
                  ? "Edit Matter Template"
                  : "Add Matter Template"}
              </Typography>
            </Box>
            <IconButton
              edge="start"
              color="white"
              onClick={handleCloseAddTemplateForm}
              aria-label="close"
            >
              <CloseRounded />
            </IconButton>
          </Box>
          <Box>
            <AddCaseTemplate
              handleCloseAddTemplateForm={handleCloseAddTemplateForm}
              isEditTempMode={isEditTempMode}
              editTempId={editTempId}
              handleCustomFieldForm={handleCustomFieldForm}
            />
          </Box>
        </Dialog>

        <Dialog
          open={openDeleteTemplateDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDelteTemplateDialog}
        >
          <DialogTitle>
            Sure you want to delete this matter template
          </DialogTitle>
          <DialogActions>
            <Button size="small" variant="outlined" color="primary">
              Edit
            </Button>

            {deleteLoad ? (
              <LoadingButton size="small" loading variant="contained">
                Delete
              </LoadingButton>
            ) : (
              <Button
                size="small"
                variant="contained"
                color="error"
                onClick={() => handleDeleteCaseTemplate(deleteTempId)}
              >
                Delete
              </Button>
            )}
          </DialogActions>
        </Dialog>

        {/* dialog for adding custom field */}
        <Dialog
          open={addFieldOpenDilog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setAddFieldOpenDilog(false)}
          sx={{
            zIndex: (theme) => theme.zIndex.modal + 2,
            "& .MuiPaper-root": {
              zIndex: 2000,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              p: "1rem",
            }}
          >
            <Box>
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                Add Custom Field
              </Typography>
            </Box>
            <Box sx={{ width: "30rem" }}>
              <form
                onSubmit={handleSubmitCustomFieldForm(onSubmitCustomFieldForm)}
              >
                <Box
                  sx={{ display: "flex", gap: "1rem", flexDirection: "column" }}
                >
                  <Box>
                    <Controller
                      name="field_name"
                      control={controlCustomFieldForm}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          size="small"
                          label="Field Name"
                          error={errorsCustomFieldForm.field_data?.type}
                        />
                      )}
                    />
                    {errorsCustomFieldForm.field_name && (
                      <Typography color="error" sx={{ fontSize: "0.8rem" }}>
                        This field is required
                      </Typography>
                    )}
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {optionLenError ? (
                        <Typography color="error" sx={{ fontSize: "0.8rem" }}>
                          This type contain minimum 2 options.
                        </Typography>
                      ) : null}
                    </Box>
                    <Controller
                      name="field_data.type"
                      control={controlCustomFieldForm}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <FormControl
                          size="small"
                          fullWidth
                          error={errorsCustomFieldForm?.field_data?.type}
                        >
                          <InputLabel
                            id="field_data-label"
                            sx={{ bgcolor: "#fff", px: "0.3rem" }}
                          >
                            Type
                          </InputLabel>
                          <Select
                            {...field}
                            size="small"
                            fullWidth
                            MenuProps={{
                              sx: {
                                zIndex: 2000,
                                height: "20rem",
                                ...pageScrollStyle,
                              },
                            }}
                          >
                            {inputTypes.map((item, i) => (
                              <MenuItem key={i} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                    {errorsCustomFieldForm.field_data?.type && (
                      <Typography color="error" sx={{ fontSize: "0.8rem" }}>
                        This field is required
                      </Typography>
                    )}
                    {field_type === "select" ? (
                      <Box
                        sx={{
                          mt: "0.5rem",
                          display: "flex",
                          justifyContent: "center ",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.5rem",
                            width: "100%",
                            maxHeight: "6rem",
                            overflowX: "auto",
                            ...pageScrollStyle,
                            pt: "0.3rem",
                          }}
                        >
                          {[...Array(optionCount)].map((item, i) => (
                            <Controller
                              key={i}
                              name={`field_data.options.${i}`}
                              control={controlCustomFieldForm}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  error={
                                    errorsCustomFieldForm.field_data?.options
                                  }
                                  fullWidth
                                  size="small"
                                  label={`option${i + 1}`}
                                  value={field.value || ""}
                                  slotProps={{
                                    input: {
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <Tooltip title="delete">
                                            <IconButton
                                              onClick={() =>
                                                handleDeleteOption(i)
                                              }
                                            >
                                              <DeleteRoundedIcon
                                                sx={{ fontSize: "1.3rem " }}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </InputAdornment>
                                      ),
                                    },
                                  }}
                                />
                              )}
                            />
                          ))}
                          {errorsCustomFieldForm.field_data?.options && (
                            <Typography
                              color="error"
                              sx={{ fontSize: "0.8rem" }}
                            >
                              These field is required
                            </Typography>
                          )}
                        </Box>
                        <Tooltip title="Add options">
                          <IconButton
                            color="primary"
                            onClick={() => {
                              setOptionCount(optionCount + 1);
                              setOptionLenError(false);
                            }}
                          >
                            <AddRoundedIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ) : null}
                  </Box>
                  <Box>
                    <Controller
                      name="field_data.required"
                      control={controlCustomFieldForm}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <input
                              {...field}
                              type="checkbox"
                              checked={field.value}
                              onChange={(e) => field.onChange(e.target.checked)}
                              className="ml-3 mr-2"
                            />
                          }
                          label={
                            <Typography
                              sx={{ fontSize: "0.9rem", fontWeight: 400 }}
                            >
                              Required
                            </Typography>
                          }
                        />
                      )}
                    />
                    <Controller
                      name="field_data.validation"
                      control={controlCustomFieldForm}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <input
                              {...field}
                              type="checkbox"
                              checked={field.value}
                              onChange={(e) => field.onChange(e.target.checked)}
                              className="ml-3 mr-2"
                            />
                          }
                          label={
                            <Typography
                              sx={{ fontSize: "0.9rem", fontWeight: 400 }}
                            >
                              Validation
                            </Typography>
                          }
                        />
                      )}
                    />
                  </Box>
                  <Divider orientation="horizontal" flexItem />
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Controller
                      name="field_data.default_apply"
                      control={controlCustomFieldForm}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <input
                              {...field}
                              type="checkbox"
                              checked={field.value}
                              onChange={(e) => field.onChange(e.target.checked)}
                              className="ml-3 mr-2"
                            />
                          }
                          label={
                            <Typography
                              sx={{ fontSize: "0.9rem", fontWeight: 400 }}
                            >
                              Default apply on form
                            </Typography>
                          }
                        />
                      )}
                    />
                    <Box>
                      {loadingCustomField ? (
                        <LoadingButton size="small" loading variant="contained">
                          Create
                        </LoadingButton>
                      ) : (
                        <Button
                          type="submit"
                          variant="contained"
                          size="small"
                          color="primary"
                        >
                          Create
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>
        </Dialog>

        <Drawer
          anchor="right"
          open={filterTempDrawer}
          onClose={togglefilterTempDrawer(false)}
          sx={{
            "& .MuiDrawer-paper": {
              width: 320,
              p: "1rem",
              ...pageScrollStyle,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: 500, fontSize: "1rem" }}
              >
                Filters
              </Typography>
              <Button
                variant="text"
                size="small"
                endIcon={<CloseRounded />}
                onClick={handleResetFilters}
                disabled={!isFilterChanged && !selectedPracticeArea}
              >
                Reset
              </Button>
            </Box>

            {/* Filters Section */}
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              {/* Practice Area Filter */}
              <Box>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  gutterBottom
                >
                  Filter by Practice Area
                </Typography>
                <FormControl size="small" fullWidth>
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={allPracticeArea || []}
                    getOptionLabel={(option) => option?.name || ""}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    value={tempPracticeArea}
                    onChange={(event, value) => {
                      setTempPracticeArea(value);
                      setIsFilterChanged(true);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Practice Area"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </Box>
            </Box>

            {/* Footer Section */}
            <Box sx={{ mt: "auto", pt: 2 }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleApplyFilters}
                disabled={!isFilterChanged}
              >
                Apply Filter
              </Button>
            </Box>
          </Box>
        </Drawer>
      </Box>
    </>
  );
};

export default CaseTemplate;
