import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Box,
  Divider,
} from "@mui/material";
import { motion } from "framer-motion";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { VisibilityOffRounded, VisibilityRounded } from "@mui/icons-material";
import RetroGridBg from "../RetroGridBg";
import { companyLogo } from "../../static/staticdata";
import LawyerGroupImg from "../../assets/lawyer-group.webp";
import { backend_api } from "../../static/server";
import {
  setLawerId,
  setToken,
  setUser,
  setUserId,
} from "../../redux/Apps/users/userSlice";

const SignupPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      phoneNumber: "",
      firmName: "",
    },
  });

  const password = watch("password");

  const handleSignup = async (data) => {
    setError("");
    try {
      const body = new URLSearchParams();
      body.append("email", data.email);
      body.append("password", data.password);
      body.append("firstName", data.firstName);
      body.append("lastName", data.lastName);
      body.append("phoneNumber", data.phoneNumber);
      body.append("firmName", data.firmName);

      const response = await axios.post(`${backend_api}lawer/signup/`, body, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      const responseData = response?.data?.data;
      if (responseData?.user) {
        dispatch(setToken(responseData.user.token));
        dispatch(setUser(responseData.user));
        dispatch(setLawerId(responseData.id));
        dispatch(setUserId(responseData.user.id));
        navigate("/dashboard");
        localStorage.setItem(
          "UserId",
          JSON.stringify({
            token: responseData.user.token,
            emailId: responseData.user.email,
          })
        );
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.message || "An error occurred");
      } else {
        setError("Network or server error. Please try again.");
      }
      console.error("Signup error", error);
    }
  };

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  return (
    <div className="min-h-screen flex flex-col-reverse 800px:flex-row overflow-hidden">
      {/* Left Side: Form Section */}
      <motion.div
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        className="w-full 800px:w-1/2 relative text-white flex items-center justify-center flex-col p-5"
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            bgcolor: "#004242",
            borderRadius: "3rem",
          }}
        >
          <Box sx={{ height: "20rem" }}>
            <img
              src={LawyerGroupImg}
              alt="Lawyer Group"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
            />
          </Box>

          <div className="relative z-10 text-center mt-4">
            <h2 className="text-4xl font-bold">Welcome to Our Platform</h2>
            <p className="mt-4 text-lg">
              Join us and enjoy exclusive features designed just for you!
            </p>
          </div>
        </Box>
      </motion.div>
      {/* Right Side: Image Section */}
      <motion.div
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        className="w-full 800px:w-1/2 bg-white flex flex-col justify-center items-center"
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            position: "relative",
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              position: "relative",
              zIndex: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.1rem",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 4,
              }}
            >
              <Box sx={{ height: "2rem" }}>
                <img
                  src={companyLogo}
                  alt="Company Logo"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  onError={(e) => {
                    e.onerror = null;
                    e.target.src = require("../../assets/nav-lexmom.webp");
                  }}
                />
              </Box>

              <Typography
                variant="body1"
                color="primary"
                sx={{ fontSize: "1.5rem", fontWeight: "500" }}
              >
                Sign Up
              </Typography>
            </Box>
            <Box sx={{ position: "relative", zIndex: 5 }}>
              <form
                onSubmit={handleSubmit(handleSignup)}
                className="space-y-4 w-full max-w-sm"
              >
                <Box
                  sx={{
                    display: "grid",
                    gap: 2,
                    gridTemplateColumns: "1fr 1fr",
                  }}
                >
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{ required: "First name is required" }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="First Name"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />

                  <Controller
                    name="lastName"
                    control={control}
                    rules={{ required: "Last name is required" }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Last Name"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Box>

                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Invalid email address",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      size="small"
                      fullWidth
                      label="Email"
                      type="email"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />

                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    required: "Phone number is required",
                    pattern: {
                      value: /^\d{10}$/,
                      message: "Please enter a valid 10-digit number",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      size="small"
                      fullWidth
                      label="Phone Number"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />

                <Controller
                  name="firmName"
                  control={control}
                  rules={{ required: "Firm name is required" }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      size="small"
                      fullWidth
                      label="Firm Name"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />

                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      size="small"
                      fullWidth
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      error={!!error}
                      helperText={error?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                togglePasswordVisibility("password")
                              }
                              edge="end"
                              color="primary"
                            >
                              {showPassword ? (
                                <VisibilityOffRounded />
                              ) : (
                                <VisibilityRounded />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                <Controller
                  name="confirmPassword"
                  control={control}
                  rules={{
                    required: "Please confirm your password",
                    validate: (value) =>
                      value === password || "Passwords do not match",
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      size="small"
                      fullWidth
                      label="Confirm Password"
                      type={showConfirmPassword ? "text" : "password"}
                      error={!!error}
                      helperText={error?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                togglePasswordVisibility("confirmPassword")
                              }
                              edge="end"
                              color="primary"
                            >
                              {showConfirmPassword ? (
                                <VisibilityOffRounded />
                              ) : (
                                <VisibilityRounded />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                {error && <p className="text-red-700">{error}</p>}

                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ p: "0.4rem", fontSize: "1rem" }}
                >
                  Sign Up
                </Button>
              </form>

              <Divider
                sx={{
                  "&::before, &::after": { borderColor: "primary.main" },
                  fontSize: "0.9rem",
                  mt: "1rem",
                }}
                orientation="horizontal"
                flexItem
              >
                or
              </Divider>
              <Box sx={{ display: "flex", gap: "0.2rem" }}>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                  }}
                >
                  Already have an account?
                </Typography>
                <Typography
                  onClick={() => navigate("/login")}
                  sx={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "0.9rem",
                  }}
                >
                  Log in
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              overflow: "hidden",
              zIndex: 1,
              top: 0,
              left: 0,
            }}
          >
            <RetroGridBg />
          </Box>
        </Box>
      </motion.div>
    </div>
  );
};

export default SignupPage;
