import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const createCustomField = createAsyncThunk(
  "createCustomField",
  async ({ url, body, token }) => {
    try {
      const response = await axios.post(url, body, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      toast.error("Failed to create custom field");
      throw error.response?.data || error.message;
    }
  }
);

export const getAllCustomField = createAsyncThunk(
  "getAllCustomField",
  async ({ url, token }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      toast.error("Failed to fetch custom fields");
      throw error.response?.data || error.message;
    }
  }
);

export const updateCustomField = createAsyncThunk(
  "updateCustomField",
  async ({ url, body, token }, { rejectWithValue }) => {
    try {
      const response = await axios.put(url, body, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const customFieldSlice = createSlice({
  name: "customField",
  initialState: {
    allCustomField: null,
    loadingCustomField: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCustomField.pending, (state) => {
        state.loadingCustomField = true;
        state.error = null;
      })
      .addCase(createCustomField.fulfilled, (state) => {
        state.loadingCustomField = false;
      })
      .addCase(createCustomField.rejected, (state, action) => {
        state.loadingCustomField = false;
        state.error = action.payload || "Failed to create custom field";
      })
      .addCase(getAllCustomField.pending, (state) => {
        state.loadingCustomField = true;
        state.error = null;
      })
      .addCase(getAllCustomField.fulfilled, (state, action) => {
        state.loadingCustomField = false;
        state.allCustomField = action.payload;
      })
      .addCase(getAllCustomField.rejected, (state, action) => {
        state.loadingCustomField = false;
        state.error = action.payload || "Failed to fetch custom fields";
      })
      .addCase(updateCustomField.pending, (state) => {
        state.loadingCustomField = true;
        state.error = null;
      })
      .addCase(updateCustomField.fulfilled, (state, action) => {
        state.loadingCustomField = false;
        state.allCustomField = action.payload;
      })
      .addCase(updateCustomField.rejected, (state, action) => {
        state.loadingCustomField = false;
        state.error = action.payload || "Failed to fetch custom fields";
      });
  },
});

export const CustomFieldReducer = customFieldSlice.reducer;
