import React, { useEffect, useState } from "react";
import {
  Box,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Typography,
  Tooltip,
  InputBase,
  Paper,
} from "@mui/material";
import { FaSignOutAlt } from "react-icons/fa";
import { MdOutlineMenu, } from "react-icons/md";
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { useNavigate } from "react-router-dom";
import { useDispatch, } from "react-redux";
import { logout } from "../../../redux/Apps/users/userSlice";
import { companyLogo } from "../../../static/staticdata";

const Navbar = ({ handleDrawerOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searching, setSearching] = useState(false)
  const [searchBox, setSearchBox] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const [userData, setUserData] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  const items = ['Apple', 'Banana', 'Orange', 'Mango', 'Grapes', 'Pineapple'];

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term.trim()) {
      setFilteredItems(
        items.filter((item) =>
          item.toLowerCase().includes(term.toLowerCase())
        )
      );
      setSearching(true);
    } else {
      setSearching(false);
      setFilteredItems([]);
    }
  };


  const handleLogout = () => {
    navigate("/login");
    handleClose();
    dispatch(logout());
    localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem("persist:root")
  }


  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem("UserId"));
    if (storedUserData) {
      setUserData(storedUserData);
    }
  }, []);

  return (
    <Box sx={{ width: '100%', bgcolor: "primary.main", p: "0.5rem 1rem" }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box sx={{ height: '2rem' }}>
            <img
              src={companyLogo}
              alt="Company Logo"
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'contain',
                filter: "brightness(0) invert(1)"
              }}
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("../../../assets/nav-lexmom.webp");
              }}
            />
          </Box>
        </Box>


        <Box sx={{ display: 'flex', alignItems: 'center', gap: "0.5rem", position: "relative" }}>
          <Box sx={{ position: 'relative', }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                bgcolor: '#fff',
                borderRadius: '2rem',
                px: '0.5rem',
                py: '0.3rem',
                border: '1px solid #ccc',
                width: searchBox ? "15rem" : "2.6rem",
                transition: "all 0.2s"
              }}
            >
              <InputBase
                fullWidth
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <SearchRoundedIcon sx={{ cursor: 'pointer', }} onClick={() => setSearchBox(!searchBox)} />
            </Box>

            {/* Dropdown / Dialog Box */}
            {searching && (
              <Paper
                sx={{
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                  right: 0,
                  mt: 1,
                  maxHeight: 200,
                  overflowY: 'auto',
                  borderRadius: 2,
                  boxShadow: 3, zIndex: 9
                }}
              >
                {filteredItems.length > 0 ? (
                  filteredItems.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        px: 2,
                        py: 1,
                        cursor: 'pointer',
                        '&:hover': { bgcolor: '#f5f5f5' },
                      }}
                      onClick={() => {
                        setSearchTerm(item);
                        setSearching(false);
                      }}
                    >
                      <Typography>{item}</Typography>
                    </Box>
                  ))
                ) : (
                  <Box sx={{ px: 2, py: 1 }}>
                    <Typography color="text.secondary">No results found</Typography>
                  </Box>
                )}
              </Paper>
            )}
          </Box>
          <Tooltip title="Notifications">
            <IconButton color="white">
              <NotificationsActiveRoundedIcon sx={{ fontSize: "1.6rem" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Settings">
            <IconButton color="white">
              <SettingsRoundedIcon sx={{ fontSize: "1.6rem" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              sx={{ ml: 2 }}
            >
              <Avatar sx={{ textTransform: "capitalize", width: "2.2rem", height: "2.2rem" }}
                alt={userData?.emailId}
                src={userData && userData?.avatar ? userData?.avatar : userData?.emailId}
              />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 4,
              sx: {
                mt: 1.5,
                minWidth: 200,
              },
            }}
          >
            <MenuItem onClick={() => { handleClose(); navigate("/dashboard/profile") }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: "1rem" }}>
                <Avatar sx={{ textTransform: "capitalize", width: "1.8rem", height: "1.8rem" }}
                  alt={userData?.emailId}
                  src={userData && userData?.avatar ? userData?.avatar : userData?.emailId}
                />
                <Typography variant="body1" sx={{ fontSize: "1rem", fontWeight: 500 }}>Profile</Typography>
              </Box>
            </MenuItem>

            <Divider />

            <MenuItem
              onClick={handleLogout}
            >
              <ListItemIcon>
                <FaSignOutAlt />
              </ListItemIcon>
              <Typography variant="body1">Logout</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

export default Navbar;